<div *ngIf="isLoggedIn">
	<div style="border: 1px solid #000; display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
		<div style="width: 40vw;">
			<!-- Title -->


			<!-- Spinner -->
			<div *ngIf="showSpinnerForAction" class="text-center">
				<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
				<h6 class="card-title" style="color: green; margin: auto; font-size: 18px;">
					Please Wait Updating LR...
				</h6>
			</div>

			<!-- Form Card -->
			<div class="card" style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); padding: 20px;">
				<div class="card-body">
					<form>
						<!-- Art Type Checkboxes -->
						<div class="form-group row align-items-center mb-3">
							<label class="col-4 text-right">Art Type:</label>
							<div class="col-8 d-flex align-items-center">
								<div class="form-check me-3" style="white-space: nowrap;">
									<input type="checkbox" class="form-check-input" id="{{pageId}}isSingleArt"
										[checked]="selectedArtType === 'single'" (change)="onArtTypeChange('single')">
									<label class="form-check-label" for="{{pageId}}isSingleArt">Single Art</label>
								</div>
								<div class="form-check" style="white-space: nowrap;">
									<input type="checkbox" class="form-check-input" id="{{pageId}}isAllArts"
										[checked]="selectedArtType === 'all'" (change)="onArtTypeChange('all')">
									<label class="form-check-label" for="{{pageId}}isAllArts">All Arts</label>
								</div>
							</div>
						</div>



						<!-- LR Number -->
						<div class="form-group row align-items-center mb-3">
							<label class="col-4 text-right">LR Number:</label>
							<div class="col-8">
								<input type="text" class="form-control" id="{{pageId}}lrNumber"
									[(ngModel)]="enteredLrNumber" name="lrNumber" style="width: 100%;">
							</div>
						</div>

						<!-- Article No -->
						<div *ngIf="showArticleNo" class="form-group row align-items-center mb-3">
							<label class="col-4 text-right">Article No:</label>
							<div class="col-8">
								<input type="text" class="form-control" id="{{pageId}}articleNo"
									[(ngModel)]="enteredArticle" name="articleNo" style="width: 100%;">
							</div>
						</div>
					</form>
				</div>
			</div>

			<!-- Buttons -->
			<div class="text-end mt-3">
				<button type="button" class="btn btn-primary me-2" (click)="reGenerateBarcode()">Generate
					Barcode</button>
				<button type="button" class="btn btn-secondary" (click)="clearFields()">Reset</button>
			</div>
		</div>
	</div>

	<div id="printSection" hidden="true">
		<div *ngFor="let PS of PrintBarcode" class="barcode-page">
			<div class="barcode-text">{{ PS.barcode }}</div>
			<ngx-barcode6 class="barcode-print" [bc-format]="'CODE128'" [bc-width]="4" [bc-height]="90"
				[bc-font-size]="30" [bc-value]="PS.barcode" [bc-display-value]="false">
			</ngx-barcode6>
			<div>
				<div class="barcode-details">LR No. {{ PS.Name }}</div>
				<div class="barcode-details">{{ PS.Destination }}</div>
				<div class="barcode-details-art">art. {{ PS.ArticleIndex }}</div>
			</div>
		</div>
	</div>
</div>