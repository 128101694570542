//PFCHG_V1 @Asrar Chg, send whatsapp notification of collection closed details to consignee
//CMR_CHG_V2 @Imran Chg,Added summary for GC Amount Colleccted confirm on 15/07/2024

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MemoService } from 'src/app/dataService/memo-service';

@Component({
	selector: 'app-collection-man-report',
	templateUrl: './collection-man-report.component.html',
	styleUrls: ['./collection-man-report.component.css']
})
export class CollectionManReportComponent implements OnInit {

	getDataGcTakenByDataListTable: any;
	getDataGcAmountCollectedTable: any;
	getDataGcLeftToParty: any;
	getDataGcLeftToPartyStatementSummary: any;
	getDataServiceFrCollectionConsolidatedReportTable: any;
	getDataServiceFrLeftToPartyStatementTable: any;
	getDataServiceFrCashmemoStockSummaryTable: any;
	getDataServiceFrCashmemoStockDetailsTable: any;
	getDataServiceFrCashmemoStockPartyWiseTable: any;
	getDataServiceFrDailyCollectionDetailsTable: any;

	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	// dynamic Id append
	pageId = "colmr";
	//Custom Print Footer
	memoAmountSummary: number;
	lessSummary: number;
	tdsSummary: number;
	claimSummary: number;
	chqAmtSummary: number;
	neftAmtSummary: number;
	cashAmtSummary: number;
	amtCollectedSummary: number;
	articleSummary: number;
	totalMemoAmountSummary: number;
	gcOutSummary: number;
	gcInSummary: number;
	gcBalanceSummary: number;
	gcAmountSummary1: number;
	gcAmountSummary2: number;
	gcAmountSummary3: number;
	pndMemosSummary: number;
	pendingMemoAmtSummary: number;
	totalMemoSummary: number;
	totalAmtSummary: number;
	totalStockLrsSummary: number;
	totalStockAmtSummary: number;
	totalAssignedLrsSummary: number;
	totalAssignedAmtSummary: number;
	totalToPartyLrsSummary: number;
	totalToPartyAmtSummary: number;
	noOfgcSummary: number;
	tdsLessSummary: number;
	claimLessSummary: number;
	neftImpsSummary: number;


	gcTakenByDataList: any;
	gcAmountCollectedDataList: any;
	gcAmountCollectedDataListDaysDiff: any;
	gcLeftToPartyDataList: any;
	gcLeftToPartyStatementSummaryDataList: any;
	collectionConsolidatedReportDataList: any;
	leftToPartyStatementDataList: any;
	cashmemoStockSummaryDataList: any;
	cashmemoStockDetailsDataList: any;
	cashmemoStockPartyWiseDataList: any;
	dailyCollectionDetailsDataList: any;
	gcAmountCollectedConfirmDataList: any;

	onDestroyUnsubscribtionGcTakenBy: Subscription;
	onDestroyUnsubscribtionGcAmountCollected: Subscription;
	onDestroyUnsubscribtionGcLeftToParty: Subscription;
	onDestroyUnsubscribtionGcLeftToPartyStatementSummary: Subscription;
	onDestroyUnsubscribtionCollectionConsolidatedReport: Subscription;
	onDestroyUnsubscribtionLeftToPartyStatement: Subscription;
	onDestroyUnsubscribtionCashmemoStockSummary: Subscription;
	onDestroyUnsubscribtionCashmemoStockDetails: Subscription;
	onDestroyUnsubscribtionCashmemoStockPartyWise: Subscription;
	onDestroyUnsubscribtionDailyCollectionDetails: Subscription;


	//for the select option with filter starts

	control = new FormControl();

	partyOptions = [
		{ id: 1, label: 'AR Tiles' },
		{ id: 2, label: 'GK Enterprises' },
		{ id: 3, label: 'GQ Associates' },
	];

	collectionManOptions = [
		{ id: 1, label: 'Aamer' },
		{ id: 2, label: 'Adnan' },
		{ id: 3, label: 'Afzal' },
	];
	areaNameOptions = [
		{ id: 1, label: 'DD' },
		{ id: 2, label: 'Local' },
		{ id: 3, label: 'Long DD' },
	];

	//for the select option with filter ends



	//summaryTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;


	searchByInvoiceNumber = true;
	searchByDate = false;
	viewSource = false;
	viewDestination = false;
	viewStationDestination = false;
	viewStationInvoice = false;

	viewReportMode = true;


	viewFromDate = true;
	viewToDate = true;
	viewPartyName = true;
	viewCollectionMan = true;
	viewAreaName = false;
	viewCashmemoStockOptions = false;
	viewSendSms = false;


	searchWithSummary = false;
	searchWithDetail = false;
	searchWithPartyWise = false;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends


	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerGcTakenBy: Subject<any> = new Subject();
	dtTriggerGcAmountCollected: Subject<any> = new Subject();
	dtTriggerGcLeftToParty: Subject<any> = new Subject();
	dtTriggerGcLeftToPartyStatementSummary: Subject<any> = new Subject();
	dtTriggerCollectionConsolidatedReport: Subject<any> = new Subject();
	dtTriggerLeftToPartyStatement: Subject<any> = new Subject();
	dtTriggerCashmemoStockSummary: Subject<any> = new Subject();
	dtTriggerCashmemoStockDetails: Subject<any> = new Subject();
	dtTriggerCashmemoStockPartyWise: Subject<any> = new Subject();
	dtTriggerDailyCollectionDetails: Subject<any> = new Subject();
	dtTriggerGcAmountCollectedConfirm: Subject<any> = new Subject();
	dtTriggerGcAmountCollectedDaysDiff: Subject<any> = new Subject();
	dataTable: any;

	dtOptionsGcTakenBy: any;
	dtOptionsGcAmountCollected: any;
	dtOptionsGcLeftToParty: any;
	dtOptionsGcLeftToPartyStatementSummary: any;
	dtOptionsCollectionConsolidatedReport: any;
	dtOptionsLeftToPartyStatement: any;
	dtOptionsCashmemoStockSummary: any;
	dtOptionsCashmemoStockDetails: any;
	dtOptionsCashmemoStockPartyWise: any;
	dtOptionsDailyCollectionDetails: any;
	dtOptionsGcAmountCollectedConfirm: any;
	dtOptionsGcAmountCollectedDaysDiff: any;

	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	colmrIsChecked = false;

	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//for ConsigneeName
	consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
	consigneeNameTA: Array<PartyMasterDto> = [];
	consigneeNameTATemp: Array<PartyMasterDto> = [];
	consigneeNameTATempForDest: Array<PartyMasterDto> = [];
	focusConsigneeNameTA$ = new Subject<string>();
	consigneeNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusConsigneeNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.consigneeNameTA
				: this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;
	public modelDestinationFotGet: any;
	public modelConsigneeNameId: any;
	modelConsigneeName: any;
	consigneeNameOptions: any;
	partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
	selectedConsigneeId: any;
	selectedDestination: any;
	destView = false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;
	office: any;
	selecDestinationForTemp: any;
	//for collection Man
	collectionManAllOption: UserDataDto = new UserDataDto();
	collectionManTA: Array<UserDataDto> = [];
	collectionManTATemp: Array<UserDataDto> = [];
	collectionManTATempForDest: Array<UserDataDto> = [];

	focusCollectionManTA$ = new Subject<string>();
	collectionManSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCollectionManTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.collectionManTA
				: this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;
	collectionManDataList: any;


	////Area

	lrDtoBranchArea: LRDto = new LRDto();
	lrDtoAreaAll: LRDto = new LRDto();
	branchAreaDataList: any;
	areaNameTA: Array<LRDto> = [];
	areaNameTATemp: Array<LRDto> = [];
	areaNameTempForDest: Array<LRDto> = [];
	focusAreaNameTA$ = new Subject<string>();
	areaNameSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusAreaNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.areaNameTA
				: this.areaNameTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterAreaName = (x: { godownName: string }) => x.godownName;
	//
	modelCollectionMan: any;
	modelAreaName: any;
	userDtoCollectionMan: UserDataDto = new UserDataDto();
	userDtoCollectionManAll: UserDataDto = new UserDataDto();
	showDaily = false;
	showTillDate = true;
	setTodayDateOnToDate: any;
	setTodayDateOnFromDate: any;
	selectedSearchBy: any;
	selectedReportMode: any;
	selectedFromDate: any;
	selectedToDate: any;
	selectedSmsNo: any;
	cashmemoDtoForDailyCollectionSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCTakenSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCLeftToPartySearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForGCAmountCollectedSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoForColletionConsolidatedSearchDetails: CashMemoDto = new CashMemoDto();
	cashmemoDtoCashMemoStockSummarySearchDetails: CashMemoDto = new CashMemoDto();
	showSmsBtn = false;
	gcTakenByTable = true;
	selectedCollectionMan: any;
	selectedConsigneeName: any;
	dailyCollectionDetailsTable = false;
	gcLeftToPartyTable = false;
	gcLeftToPartyStatementSummaryTable = false;
	gcAmountCollectedTable = false;
	leftToPartyStatementTable = false;
	collectionConsolidatedTable = false;
	cashmemoStockSummaryTable = false;
	cashmemoStockDetailsTable = false;
	cashmemoStockPartyWiseTable = false;
	gcAmountCollectedConfirmTable = false;
	cashMemoDtoForGCTakenPrint: CashMemoDto = new CashMemoDto();
	viewGCTakenPrint = false;
	viewCustomPrintV1 = false;
	showDest = 'CollectionManRpt DestView';
	toSetDestForConsignee: any;
	toSetDestForColMan: any;
	toSetDestForArea: any;
	cashMemoDtoForGCLeftToPartyPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForGCTaken: CashMemoDto = new CashMemoDto();
	cashMemoDtoForGCLeftToPartyGrpPrint: CashMemoDto = new CashMemoDto();
	viewGCLeftToPartyPrint = false;
	collectionManSelected = null;
	pageMode = null;
	modalRefferenceLeftToPartyStmtPopUp: NgbModalRef;
	modalRefferenceLeftToPartyStmtClosePopUp: string;
	@ViewChild('leftToPartyStmtPopUpTemplate') private leftToPartyStmtPopUpTemplate;
	selectedLeftToPartyStmt1Details: File;
	selectedLeftToPartyStmt2Details: File;
	selectedLeftToPartyStmt3Details: File;
	isUploaded: boolean = false;
	cashMemoDtoForGCLeftToPartyAckRecUpdate: CashMemoDto = new CashMemoDto();
	ackReckPopupStmtNo: any;
	showSpinnerForActionForUpload = false;
	//Station 
	modelStation: any;
	stationTA: Array<PartyMasterDto> = [];
	focusStationTA$ = new Subject<string>();
	stationSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusStationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.stationTA
				: this.stationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterStation = (x: { destination: string }) => x.destination;
	////
	lrDtoForStation: LRDto = new LRDto();
	stationOptions: any;
	selectedAreaName: any;
	selectedSearchByStock: any;
	todayDate: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];

	cashMemoDtoForSms: CashMemoDto = new CashMemoDto();
	listCollectionMan: Array<any> = [];
	listArea: Array<any> = [];

	modalRefferencePrintPreviewLeftToPartyPopUp: NgbModalRef;
	modalRefferencePrintPreviewPreviewLeftToPartyClosePopUp: string;
	@ViewChild('printPreviewStmtPopUpPopUpTemplate') private printPreviewStmtPopUpPopUpTemplate;

	viewPrintPopupLeftToParty: boolean = false;
	viewLeftToPartyCustomPrintV1: boolean = false;
	viewGcTakenGroupingByConsigneeRptPrint: boolean = false;
	viewGcTakenLeftToPartyGroupingByConsigneeRptPrint: boolean = false;
	printToSentStmtNo: string;

	cashmemoDtoAmtCollectedConfirmChecked: CashMemoDto = new CashMemoDto();
	cashmemoDtoAmtCollectedConfirmCheckedList: Array<CashMemoDto> = [];
	cashmemoDtoAmtCollectedConfirmUpdateRet = null;
	showAmtCollectedConfirmOption: boolean = false;
	showAmtCollectedConfirmOptionBtn: boolean = false;
	cashMemoDtoForPaymentChallanPrint: CashMemoDto = new CashMemoDto();
	viewPaymentChallanPrint = false;
	showPaymentFollowupReprint = false;
	showPaymentFollowupReprintAsPerRights = false;
	sendCollectionCloseWhatsappUpdates: boolean = false;
	userDataDtoForWhatsApp: UserDataDto = new UserDataDto();
	listOfMemoNumber: Array<any> = [];
	enteredDiffDays: any;
	gcAmountCollectedDaysDiffView: boolean = false;

	debitAcAmtSummary: number;
	upiAmtSummary: number;

	constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe,
		private memoReport: MemoReport, private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef,
		public memoService: MemoService) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			if (this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp != null && this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp) {
				this.sendCollectionCloseWhatsappUpdates = true;
			}

			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			$("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
			this.toSetDestForConsignee = this.userDataDtoReturnSession.mainStation;
			this.toSetDestForColMan = this.userDataDtoReturnSession.mainStation;
			this.toSetDestForArea = this.userDataDtoReturnSession.mainStation;
			this.getStationDtails();
			this.selectTodayDate();
			this.getConsigneeDetails(false);
			this.getCollectionManDetailsList(false);
			this.getBranchAreaDetailsList(false);
			/*if (this.showDest=='CollectionManRpt DestView') {
			this.destView=true;         
			}*/
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"CollectionManRpt DestView") {
						this.destView = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Collection SMS") {
						this.showSmsBtn = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Show Amt Collected Confirm Option") {
						this.showAmtCollectedConfirmOption = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Show Amt Collected Confirm Btn") {
						this.showAmtCollectedConfirmOptionBtn = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Show Payment Challan Reprint") {
						this.showPaymentFollowupReprintAsPerRights = true;
					}
				}
			}
			//this.destView=true;
			if (this.destView == true) {
				this.viewDestination = true;
				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}
			if (this.userDataDtoReturnSession.mainStation != null
				&& this.userDataDtoReturnSession.mainStation == "Chennai") {
				this.gcAmountCollectedDaysDiffView = true;
			}
		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}







	ngOnInit(): void {

		var companyAddressDetls = this.address;
		var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		var dateformate = moment(todayDate).format('DD-MM-YYYY');
		//CMR_CHG_V2
		var groupColumnCollDt = 0;
		var groupColumnAndCollNo = 19;
		//the first datatable starts
		this.dtOptionsGcTakenBy = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						var returnSummaryExcelGCTaken = null;
						if ($("#colmrreportMode").val() == 'rptModeDateWise') {
							returnSummaryExcelGCTaken = "GC Taken By Report" + " G.C Taken By : " + $("#colmrcollectionMan").val() +
								" From Date : " + moment($("#colmrfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
						} else {
							returnSummaryExcelGCTaken = "GC Taken By Report" +
								"G.C Taken By : " + $("#colmrcollectionMan").val() + " To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
						}
						return returnSummaryExcelGCTaken;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totalAmt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(5).footer()).html(totalAmt);

			}
		},
			this.dtOptionsGcAmountCollected = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							var returnSummaryExcelAmtColt = null;
							returnSummaryExcelAmtColt = "G.C AMOUNT COLLECTED REPORT " + " G.C Amount Collected By : " + $("#colmrcollectionMan").val() +
								" From Date : " + moment($("#colmrfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
							return returnSummaryExcelAmtColt;
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var amount = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var less = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var tds = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var claim = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chequeAmt = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var neftAmt = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var cashAmt = api.column(11).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);



					var debitAcAmt = api.column(12).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var upiAmt = api.column(13).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
						var cardAmt = api.column(14).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var amountCollected = api.column(15).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var article = api.column(21).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var toPay = api.column(26).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html(amount);
					$(api.column(6).footer()).html(less);
					$(api.column(7).footer()).html(tds);
					$(api.column(8).footer()).html(claim);
					$(api.column(9).footer()).html(chequeAmt);
					$(api.column(10).footer()).html(neftAmt);
					$(api.column(11).footer()).html(cashAmt);
					$(api.column(12).footer()).html(debitAcAmt);
					$(api.column(13).footer()).html(upiAmt);
					$(api.column(14).footer()).html(cardAmt);
					$(api.column(15).footer()).html(amountCollected);
					$(api.column(16).footer()).html();
					$(api.column(17).footer()).html();
					$(api.column(18).footer()).html();
					$(api.column(19).footer()).html();
					$(api.column(20).footer()).html();
					$(api.column(21).footer()).html(article);
					$(api.column(22).footer()).html();
					$(api.column(23).footer()).html();
					$(api.column(26).footer()).html(toPay);
				}

			},
			this.dtOptionsGcAmountCollectedDaysDiff = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							var returnSummaryExcelAmtColt = null;
							returnSummaryExcelAmtColt = "G.C AMOUNT COLLECTED REPORT " + " G.C Amount Collected By : " + $("#colmrcollectionMan").val() +
								" From Date : " + moment($("#colmrfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
							return returnSummaryExcelAmtColt;
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var amount = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var less = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var tds = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var claim = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chequeAmt = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var neftAmt = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var cashAmt = api.column(11).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var debitAcAmt = api.column(12).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var upiAmt = api.column(13).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var amountCollected = api.column(14).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var article = api.column(20).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var toPay = api.column(25).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html(amount);
					$(api.column(6).footer()).html(less);
					$(api.column(7).footer()).html(tds);
					$(api.column(8).footer()).html(claim);
					$(api.column(9).footer()).html(chequeAmt);
					$(api.column(10).footer()).html(neftAmt);
					$(api.column(11).footer()).html(cashAmt);
					$(api.column(12).footer()).html(debitAcAmt);
					$(api.column(13).footer()).html(upiAmt);
					$(api.column(14).footer()).html(amountCollected);
					$(api.column(15).footer()).html();
					$(api.column(16).footer()).html();
					$(api.column(17).footer()).html();
					$(api.column(18).footer()).html();
					$(api.column(19).footer()).html();
					$(api.column(20).footer()).html(article);
					$(api.column(21).footer()).html();
					$(api.column(22).footer()).html();
					$(api.column(24).footer()).html(toPay);
				}

			},
			//the second datatable ends
			//the third datatable starts            
			this.dtOptionsGcLeftToParty = {

				dom: 'Bfrtip',


				buttons: [
					/*	{
							////extend: 'print',
							text: '<i class="fas fa-print">Print</i>',
							titleAttr: 'Print',
							customize: function (win) {
								$(win.document.body).css('font-size', '10pt');
								$(win.document.body).find('th, td').
									css('font-family', 'Arial, Helvetica, sans-serif')
									.css('font-size', '13px').css('text-align', 'center');
							},
							footer: true,
							exportOptions: {
								columns: [0, 1, 2, 3, 4, 5, 6, 10]
							},
							messageTop: function () {
								var returnValGcLeftToParty = null;
								if ($("#"+this.pageId+"reportMode").val() == 'rptModeDateWise') {
									returnValGcLeftToParty = "<br><table style='width: 100%;'>" +
										"<tr>" +
										"<td style='width: 30%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + moment$("#"+this.pageId+"fromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "</span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
										"<strong style='font-size:19px;'><u>G.C Left To Party Report</u></strong><br>" +
										"</td>" +
										"<td align='left' style='width:40%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='13%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>G.C Left to Party</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='25%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + $("#"+this.pageId+"collectionMan").val() + "</span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='13%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='25%' style='text-align:left !important; word-wrap:break-word;'>" +
										"<span style='font-size:15px;'></span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"</tr>" +
										"</table><br>";
								} else {
									returnValGcLeftToParty = "<br><table style='width: 100%;'>" +
										"<tr>" +
										"<td style='width: 30%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>G.C Left to Party&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'>" + $("#"+this.pageId+"collectionMan").val() + "</span><br>" +
										"</td>" +
										"</tr>" +
	
										"</table>" +
										"</td>" +
										"<td rowspan='2'style='width:40%;word-wrap: break-word;text-align:left !important;'>" +
										"<strong style='font-size:19px;'><u>G.C Left To Party Report</u></strong><br>" +
										"</td>" +
										"<td align='left' style='width:30%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>To Date. &nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>" + moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "</strong>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"</tr>" +
										"</table><br>";
								}
	
	
								return returnValGcLeftToParty;
							},
							title: function () {
								return "<table style='table-layout: fixed;width: 100%;'><tr>" +
									"<td align='left' style='width: 20%;'>" +
									"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
									"</td>" +
									"<td align='left' style='width: 30%;'>" +
									"</td>" +
									"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
									companyAddressDetls +
									"</td>" +
									"</tr></table>";
							}
						},*/
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {

							var returnSummaryExcelGcLeftToParty = null;

							if ($("#colmrreportMode").val() == 'rptModeDateWise') {
								returnSummaryExcelGcLeftToParty = "GC Left To Party Report " + " G.C Left to Party : " + $("#colmrcollectionMan").val() +
									" From Date : " + moment($("#colmrfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
							} else {

								returnSummaryExcelGcLeftToParty = "GC Left To Party Report " +
									"G.C Left to Party : " + $("#colmrcollectionMan").val() + " To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');

							}


							return returnSummaryExcelGcLeftToParty;
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,


				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};


					var totalAmt = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);


					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html(totalAmt);
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html();
					$(api.column(7).footer()).html();
					$(api.column(8).footer()).html();
					$(api.column(9).footer()).html();
					$(api.column(10).footer()).html();
				}

			},
			//the third datatable ends
			//the fourth datatable starts
			this.dtOptionsGcLeftToPartyStatementSummary = {
				dom: 'Bfrtip',


				buttons: [
					/*{
						////extend: 'print',
						text: '<i class="fas fa-print">Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '13px').css('text-align', 'center');
						},
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 4, 6]
						},
						messageTop: function () {
							var returnValGcLeftToPartyStmtSumm = null;
							if ($("#"+this.pageId+"reportMode").val() == 'rptModeDateWise') {
								returnValGcLeftToPartyStmtSumm = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#"+this.pageId+"fromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:15px;'><u>G.C Left To Party Statement Summary Report</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:40%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='13%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>G.C Left to Party</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='25%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + $("#"+this.pageId+"collectionMan").val() + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='13%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='25%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'></span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							} else {
								returnValGcLeftToPartyStmtSumm = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>G.C Left to Party&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + $("#"+this.pageId+"collectionMan").val() + "</span><br>" +
									"</td>" +
									"</tr>" +

									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:40%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:16px;'><u>G.C Left To Party Statement Summary Report</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date. &nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>" + moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "</strong>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							}


							return returnValGcLeftToPartyStmtSumm;
						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},*/
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 4, 5, 6, 8]
							//stripHtml: false
						},
						title: function () {

							var returnSummaryExcelGcLeftToPartyStmtSumm = null;

							if ($("#colmrreportMode").val() == 'rptModeDateWise') {
								returnSummaryExcelGcLeftToPartyStmtSumm = "G.C Left To Party Statement Summary Report " + " G.C Left to Party : " + $("#colmrcollectionMan").val() +
									" From Date : " + moment($("#colmrfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
							} else {

								returnSummaryExcelGcLeftToPartyStmtSumm = "G.C Left To Party Statement Summary Report " +
									"G.C Left to Party : " + $("#colmrcollectionMan").val() + " To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');

							}


							return returnSummaryExcelGcLeftToPartyStmtSumm;
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,


				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};


					var totalAmt = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);


					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html(totalAmt);
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html();
					$(api.column(7).footer()).html();

				}
			},
			//the fourth datatable ends
			//the fifth datatable starts 
			this.dtOptionsCollectionConsolidatedReport = {
				dom: 'Bfrtip',

				buttons: [
					/*{
						////extend: 'print',
						text: '<i class="fas fa-print">Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '13px').css('text-align', 'center');
						},
						footer: true,
						messageTop: function () {
							var returnValColecConsol = null;
							returnValColecConsol = "<br><table style='width: 100%;'>" +
								"<tr>" +
								"<td style='width: 30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'></strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'></strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'></span><br>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"<td rowspan='2'style='width:40%;word-wrap: break-word;text-align:left !important;'>" +
								"<strong style='font-size:19px;'><u>Collection Consolidated Report</u></strong><br>" +
								"</td>" +
								"<td align='left' style='width:30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>Date</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>" + dateformate + "</strong>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"</tr>" +
								"</table><br>";

							return returnValColecConsol;
						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},*/
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {

							var returnSummaryExcelColecConsol = null;
							returnSummaryExcelColecConsol = "Collection Consolidated Report " +
								" Date : " + dateformate;
							return returnSummaryExcelColecConsol;
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,


				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};


					var gcOut = api.column(1).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var gcOutAmt = api.column(2).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var gcIn = api.column(3).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var gcInAmt = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var gcBal = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var gcBalAmt = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);


					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html(gcOut);
					$(api.column(2).footer()).html(gcOutAmt);
					$(api.column(3).footer()).html(gcIn);
					$(api.column(4).footer()).html(gcInAmt);
					$(api.column(5).footer()).html(gcBal);
					$(api.column(6).footer()).html(gcBalAmt);

				}
			}
		//the fifth datatable ends

		//the sixth datatable starts 
		this.dtOptionsLeftToPartyStatement = {
			dom: 'Bfrtip',


			buttons: [
				/*	{
						////extend: 'print',
						text: '<i class="fas fa-print">Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '13px').css('text-align', 'center');
						},
						footer: true,
						exportOptions: {
							columns: [0, 1, 2, 3, 4, 5]
						},
						messageTop: function () {
							var returnValLeftToPartyAckPendStmt = null;
	
							returnValLeftToPartyAckPendStmt = "<br><table style='width: 100%;'>" +
								"<tr>" +
								"<td style='width: 30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>G.C Left to Party&nbsp;&nbsp;</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + $("#"+this.pageId+"collectionMan").val() + "</span><br>" +
								"</td>" +
								"</tr>" +
	
								"</table>" +
								"</td>" +
								"<td rowspan='2'style='width:40%;word-wrap: break-word;text-align:left !important;'>" +
								"<strong style='font-size:16px;'><u>Left To Party  Statement Report</u></strong><br>" +
								"</td>" +
								"<td align='left' style='width:30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>Date&nbsp;&nbsp;</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>" + dateformate + "</strong>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"</tr>" +
								"</table><br>";
	
							return returnValLeftToPartyAckPendStmt;
						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},*/
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					exportOptions: {
						columns: ':visible'
					},
					title: function () {
						var returnSummaryExcelLeftToPartyAckPendStmt = null;
						returnSummaryExcelLeftToPartyAckPendStmt = "Left To Party  Statement Report " +
							"G.C Left to Party : " + $("#colmrcollectionMan").val() + "  Date : " + dateformate;

						return returnSummaryExcelLeftToPartyAckPendStmt;
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,


			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};


				var totalMemos = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totalAmt = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html(totalMemos);
				$(api.column(3).footer()).html(totalAmt);
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();

			}
		}
		//the sixth datatable ends

		//the seventh datatable starts 
		this.dtOptionsCashmemoStockSummary = {
			dom: 'Bfrtip',

			buttons: [
				/*	{
						////extend: 'print',
						text: '<i class="fas fa-print">Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '13px').css('text-align', 'center');
						},
						footer: true,
	
						messageTop: function () {
							var returnValCashmemoStockSum = null;
	
							returnValCashmemoStockSum = "<br><table style='width: 100%;'>" +
								"<tr>" +
								"<td style='width: 30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>As On&nbsp;&nbsp;</strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'>:</strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<span style='font-size:15px;'>" + dateformate + "</span><br>" +
								"</td>" +
								"</tr>" +
	
								"</table>" +
								"</td>" +
								"<td rowspan='2'style='width:40%;word-wrap: break-word;text-align:left !important;'>" +
								"<strong style='font-size:16px;'><u>Cashmemo Stock Summary Report</u></strong><br>" +
								"</td>" +
								"<td align='left' style='width:30%;'>" +
								"<table>" +
								"<tr>" +
								"<td width='8%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'></strong>" +
								"</td>" +
								"<td width='2%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'></strong>" +
								"</td>" +
								"<td width='10%' style='text-align:left !important;'>" +
								"<strong style='font-size:15px;'></strong>" +
								"</td>" +
								"</tr>" +
								"</table>" +
								"</td>" +
								"</tr>" +
								"</table><br>";
	
							return returnValCashmemoStockSum;
						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},*/
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						var returnSummaryExcelCashmemoStockSum = null;
						returnSummaryExcelCashmemoStockSum = "Cashmemo Stock Summary Report" + "  As On : " + dateformate;

						return returnSummaryExcelCashmemoStockSum;
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,


			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};


				var totalMemos = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totalAmt = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totArt = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totStocklrs = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totStockAmt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totAsglrs = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totAsgAmt = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totToPartylrs = api.column(9).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totToPartyAmt = api.column(10).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(totalMemos);
				$(api.column(2).footer()).html(totalAmt);
				$(api.column(3).footer()).html(totArt);
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html(totStocklrs);
				$(api.column(6).footer()).html(totStockAmt);
				$(api.column(7).footer()).html(totAsglrs);
				$(api.column(8).footer()).html(totAsgAmt);
				$(api.column(9).footer()).html(totToPartylrs);
				$(api.column(10).footer()).html(totToPartyAmt);

			}
		}
		//the seventh datatable ends

		//the eight datatable starts 
		this.dtOptionsCashmemoStockDetails = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 380,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totalAmt = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totArt = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(3).footer()).html(totalAmt);
				$(api.column(6).footer()).html(totArt);
			}
		}

		//the ninth datatable starts 
		this.dtOptionsCashmemoStockPartyWise = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 380,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalMemos = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var totalAmt = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var totArt = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html(totalMemos);
				$(api.column(2).footer()).html(totalAmt);
				$(api.column(3).footer()).html(totArt);
			}
		},
			//the ninth datatable ends

			//the tenth datatable starts 
			this.dtOptionsDailyCollectionDetails = {

				dom: 'Bfrtip',
				buttons: [
					/*{
						////extend: 'print',
						text: '<i class="fas fa-print"> Print</i>',
						titleAttr: 'Print',
						customize: function (win) {
							$(win.document.body).css('font-size', '10pt');
							$(win.document.body).find('th, td').
								css('font-family', 'Arial, Helvetica, sans-serif')
								.css('font-size', '13px').css('text-align', 'center');
						},
						footer: true,
						messageTop: function () {
							var returnValDaily = null;

							if ($("#"+this.pageId+"reportMode").val() == 'rptModeDaily') {
								returnValDaily = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date. &nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>" + moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "</strong>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:50%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:20px;'><u>Daily Booking Report</u></strong><br>" +
									"</td>" +
									"<td  align='left' style='width:20%;'>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							} else {
								returnValDaily = "<br><table style='width: 100%;'>" +
									"<tr>" +
									"<td style='width: 30%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#"+this.pageId+"fromDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'>:</strong>" +
									"</td>" +
									"<td width='10%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'>" + moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "</span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
									"<strong style='font-size:19px;'><u>Daily Booking Report</u></strong><br>" +
									"</td>" +
									"<td align='left' style='width:40%;'>" +
									"<table>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important;'>" +
									"<span style='font-size:15px;'></span><br>" +
									"</td>" +
									"</tr>" +
									"<tr>" +
									"<td width='8%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='2%' style='text-align:left !important;'>" +
									"<strong style='font-size:15px;'></strong>" +
									"</td>" +
									"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
									"<span style='font-size:15px;'></span><br>" +
									"</td>" +
									"</tr>" +
									"</table>" +
									"</td>" +
									"</tr>" +
									"</table><br>";
							}



							return returnValDaily;
						},
						title: function () {
							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
								"<td align='left' style='width: 20%;'>" +
								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
								"</td>" +
								"<td align='left' style='width: 30%;'>" +
								"</td>" +
								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
								companyAddressDetls +
								"</td>" +
								"</tr></table>";
						}
					},*/
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {

							var returnSummaryExcelDaily = null;

							if ($("#colmrreportMode").val() == 'rptModeDaily') {
								returnSummaryExcelDaily = "Daily Booking Report" +
									" To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
							} else {
								returnSummaryExcelDaily = "Daily Booking Report" +
									" From Date : " + moment($("#colmrfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
							}


							return returnSummaryExcelDaily;
						},
						exportOptions: {
							columns: ':visible'
						}
					}
				],

				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var noOfGC = api.column(3).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var totalAmt = api.column(4).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var less = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var tdsLess = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var claimLess = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var amountCollected = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var cashAmt = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var ChqAmt = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var neftAmt = api.column(11).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var debitAcAmt = api.column(12).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var upiAmt = api.column(13).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(2).footer()).html('Total : ' + data.length);
					// $(api.column(1).footer()).html();
					$(api.column(3).footer()).html(noOfGC);
					$(api.column(4).footer()).html(totalAmt);
					$(api.column(5).footer()).html(less);
					$(api.column(6).footer()).html(tdsLess);
					$(api.column(7).footer()).html(claimLess);
					$(api.column(8).footer()).html(amountCollected);
					$(api.column(9).footer()).html(cashAmt);
					$(api.column(10).footer()).html(ChqAmt);
					$(api.column(11).footer()).html(neftAmt);
					$(api.column(12).footer()).html(debitAcAmt);
					$(api.column(13).footer()).html(upiAmt);

				}

			},

			this.dtOptionsGcAmountCollectedConfirm = {
				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						title: function () {
							var returnSummaryExcelAmtColt = null;
							returnSummaryExcelAmtColt = "G.C AMOUNT COLLECTED CONFIRM REPORT " + " G.C Amount Collected By : " + $("#colmrcollectionMan").val() +
								" From Date : " + moment($("#colmrfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#colmrtoDate").val()).format('DD-MM-YYYY');
							return returnSummaryExcelAmtColt;
						}
					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": false,
				"orderable": false,
				"drawCallback": function (settings) {
					var api = this.api();
					var rows = api.rows({
						page: 'current'
					}).nodes();
						
					var last = null;
					var memoAmt = 0;
					var lessAmt = 0;
					var tdsAmt = 0;
					var claimAmt = 0;
					var chqAmt = 0;
					var neftAmt = 0;
					var cashAmt = 0;
					var debitAmt = 0;
					var upiAmt = 0;
					var amtCollected = 0;
					var count = 0;
					
					api.column(groupColumnCollDt, {
						page: 'current'
					}).data().each(function (group, i) {
						var val = api.row(api.row($(rows).eq(i)).index()).data();
						if (last !== group) {
							if (i != 0) {
								$(rows)
									.eq(i)
									.before(
										$(
											"<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
											{
												"class": "group",
												"data-id": group
											})
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": "Total : "
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": count
												}))
											.append($("<td></td>",
												{
													"colspan": 4,
													"text": ""
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": memoAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": lessAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": tdsAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": claimAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": chqAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": neftAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": cashAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": debitAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": upiAmt
												}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": amtCollected
												}))

											.append($("<td></td>",
												{
													"colspan": 6,
													"text": ""
												}))
											.prop('outerHTML'));
								memoAmt = 0;
								lessAmt = 0;
								tdsAmt = 0;
								claimAmt = 0;
								chqAmt = 0;
								neftAmt = 0;
								cashAmt = 0;
								debitAmt = 0;
								upiAmt = 0;
								amtCollected = 0;
								count = 0;
							}
							$(rows)
								.eq(i)
								.before(
									$(
										"<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append(
											$("<td></td>",
												{
													"colspan": 23,
													"text": group
												})).prop('outerHTML'));
							last = group;
						}
						count++;
						memoAmt += +val[7];
						lessAmt += +val[8];
						tdsAmt += +val[9];
						claimAmt += +val[10];
						chqAmt += +val[11];
						neftAmt += +val[12];
						cashAmt += +val[13];
						debitAmt += +val[14];
						upiAmt += +val[15];
						amtCollected += +val[16];
						if (i == (rows.length - 1)) {

							$(rows)
								.eq(i)
								.after(
									$(
										"<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
										{
											"class": "group",
											"data-id": group
										})
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": "Total : " 
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text":  count
											}))
										.append($("<td></td>",
											{
												"colspan": 4,
												"text": ""
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": memoAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": lessAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": tdsAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": claimAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": chqAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": neftAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": cashAmt
											}))
										.append($("<td></td>",
											{
												"colspan": 1,
												"text": debitAmt
											}))
											.append($("<td></td>",
												{
													"colspan": 1,
													"text": upiAmt
												}))
												.append($("<td></td>",
													{
														"colspan": 1,
														"text": amtCollected
													}))
													
										.append($("<td></td>",
											{
												"colspan": 6,
												"text": ""
											}))
										.prop('outerHTML'));
										memoAmt = 0;
										lessAmt = 0;
										tdsAmt = 0;
										claimAmt = 0;
										chqAmt = 0;
										neftAmt = 0;
										cashAmt = 0;
										debitAmt = 0;
										upiAmt = 0;
										amtCollected = 0;
										count = 0;
						}
					});
				},
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};
					var amount = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var less = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var tds = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var claim = api.column(9).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var chequeAmt = api.column(10).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var neftAmt = api.column(11).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var cashAmt = api.column(12).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var debitAcAmt = api.column(13).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var upiAmt = api.column(14).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var amountCollected = api.column(15).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var article = api.column(20).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html();
					$(api.column(1).footer()).html('Total : ' + data.length);
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(4).footer()).html();
					$(api.column(5).footer()).html();
					$(api.column(6).footer()).html(amount);
					$(api.column(7).footer()).html(less);
					$(api.column(8).footer()).html(tds);
					$(api.column(9).footer()).html(claim);
					$(api.column(10).footer()).html(chequeAmt);
					$(api.column(11).footer()).html(neftAmt);
					$(api.column(12).footer()).html(cashAmt);
					$(api.column(13).footer()).html(debitAcAmt);
					$(api.column(14).footer()).html(upiAmt);
					$(api.column(15).footer()).html(amountCollected);
					$(api.column(16).footer()).html();
					$(api.column(17).footer()).html();
					$(api.column(18).footer()).html();
					$(api.column(19).footer()).html();
					$(api.column(20).footer()).html();
					$(api.column(21).footer()).html(article);
					$(api.column(22).footer()).html();
					$(api.column(23).footer()).html();
					
					
				}
			}


	}




	ngOnDestroy(): void {
		this.dtTriggerGcTakenBy.unsubscribe();
		this.dtTriggerGcAmountCollected.unsubscribe();
		this.dtTriggerGcLeftToParty.unsubscribe();
		this.dtTriggerGcLeftToPartyStatementSummary.unsubscribe();
		this.dtTriggerCollectionConsolidatedReport.unsubscribe();
		this.dtTriggerLeftToPartyStatement.unsubscribe();
		this.dtTriggerCashmemoStockSummary.unsubscribe();
		this.dtTriggerCashmemoStockDetails.unsubscribe();
		this.dtTriggerCashmemoStockPartyWise.unsubscribe();
		this.dtTriggerDailyCollectionDetails.unsubscribe();
		this.dtTriggerGcAmountCollectedConfirm.unsubscribe();
		if (this.gcAmountCollectedDaysDiffView) {
			this.dtTriggerGcAmountCollectedDaysDiff.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		this.dtTriggerGcTakenBy.next();
		this.dtTriggerGcAmountCollected.next();
		this.dtTriggerGcLeftToParty.next();
		this.dtTriggerGcLeftToPartyStatementSummary.next();
		this.dtTriggerCollectionConsolidatedReport.next();
		this.dtTriggerLeftToPartyStatement.next();
		this.dtTriggerCashmemoStockSummary.next();
		this.dtTriggerCashmemoStockDetails.next();
		this.dtTriggerCashmemoStockPartyWise.next();
		this.dtTriggerDailyCollectionDetails.next();
		this.dtTriggerGcAmountCollectedConfirm.next();
		if (this.gcAmountCollectedDaysDiffView) {
			this.dtTriggerGcAmountCollectedDaysDiff.next();
		}
	}

	clearAllTable() {

		this.dtTriggerDailyCollectionDetails.next();
		this.dtTriggerGcLeftToParty.next();
		this.dtTriggerGcTakenBy.next();
		this.dtTriggerGcLeftToPartyStatementSummary.next();
	}


	//report mode hide and show input fields starts
	reportModeMode(reportMode: string) {
		if (reportMode === 'rptModeTillDate') {
			this.viewFromDate = false;
			this.viewToDate = true;
			this.viewSendSms = false;
			this.showPaymentFollowupReprint = false;
		} else if (reportMode === 'rptModeDateWise') {
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewSendSms = false;
			this.showPaymentFollowupReprint = false;

		} else if (reportMode === 'rptModeDaily') {
			this.viewFromDate = false;
			this.viewToDate = true;
			this.viewSendSms = true;
			if (this.showPaymentFollowupReprintAsPerRights) {
				this.showPaymentFollowupReprint = true;
			}

		} else {
			this.viewFromDate = false;
			this.viewToDate = false;
			this.viewSendSms = false;
			this.showPaymentFollowupReprint = false;

		}
	}
	//report mode hide and show input fields ends

	//the sub of cashmemo Stock (Datatable hide and show)starts
	searchWithMode(searchWith: string) {
		if (searchWith === 'summary') {
			this.searchWithSummary = true;
			this.searchWithDetail = false;
			this.searchWithPartyWise = false;
			this.cashmemoStockSummaryTable = true;
			this.cashmemoStockDetailsTable = false;
			this.cashmemoStockPartyWiseTable = false;

		} else if (searchWith === 'detail') {
			this.searchWithSummary = false;
			this.searchWithDetail = true;
			this.searchWithPartyWise = false;
			this.cashmemoStockSummaryTable = false;
			this.cashmemoStockDetailsTable = true;
			this.cashmemoStockPartyWiseTable = false;

		} else if (searchWith === 'partyWise') {
			this.searchWithSummary = false;
			this.searchWithDetail = false;
			this.searchWithPartyWise = true;
			this.cashmemoStockSummaryTable = false;
			this.cashmemoStockDetailsTable = false;
			this.cashmemoStockPartyWiseTable = true;

		} else {
			this.searchWithSummary = false;
			this.searchWithDetail = false;
			this.searchWithPartyWise = false;
			this.cashmemoStockSummaryTable = false;
			this.cashmemoStockDetailsTable = false;
			this.cashmemoStockPartyWiseTable = false;

		}
	}
	//the sub of cashmemo Stock (Datatable hide and show)ends


	hideTable() {
		this.dailyCollectionDetailsTable = false;
		this.gcTakenByTable = false;
		this.gcLeftToPartyTable = false;
		this.gcLeftToPartyStatementSummaryTable = false;
		this.gcAmountCollectedTable = false;
		this.leftToPartyStatementTable = false;
		this.collectionConsolidatedTable = false;
		this.cashmemoStockSummaryTable = false;
		this.cashmemoStockDetailsTable = false;
		this.cashmemoStockPartyWiseTable = false;
		this.gcAmountCollectedConfirmTable = false;

		if (this.destView == true) {
			this.viewDestination = true;
			$("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
			this.modelConsigneeName = '';
			this.consigneeNameTA = [];
			this.consigneeNameTA = this.consigneeNameTATempForDest;
			this.modelCollectionMan = '';
			this.collectionManTA = [];
			this.collectionManTA = this.collectionManTATempForDest;
			this.modelAreaName = '';
			this.areaNameTA = [];
			this.areaNameTA = this.areaNameTempForDest;
			window.setTimeout(function () {
				$("#" + this.pageId + "consigneeName").val('All');
				$("#" + this.pageId + "collectionMan").val('All');
				$("#" + this.pageId + "areaName").val('All');
			}, 100);

		} else {
			this.modelConsigneeName = '';
			this.consigneeNameTA = this.consigneeNameTATemp;
			this.modelCollectionMan = '';
			this.collectionManTA = this.collectionManTATemp;
			this.viewDestination = false;
			this.modelAreaName = '';
			this.areaNameTA = this.areaNameTATemp;
			window.setTimeout(function () {
				$("#" + this.pageId + "consigneeName").val('All');
				$("#" + this.pageId + "collectionMan").val('All');
				$("#" + this.pageId + "areaName").val('All');
			}, 100);

		}
	}


	//input field and datatable hide and show starts
	searchByMode(searchBy: string) {
		this.selectTodayDate();
		if (searchBy === 'gcTakenBy') {
			//input field hide and show starts
			this.viewReportMode = true;

			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewPartyName = true;
			this.viewCollectionMan = true;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = true;
			this.searchByDate = false;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			// this.dailyCollectionDetailsTable=false;
			this.hideTable();
			this.showTillDate = true;
			this.showDaily = false;
			this.clearDataTable();
			this.gcTakenByTable = true;
			this.viewSendSms = false;
			this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
			if (this.selectedReportMode == 'rptModeDateWise') {
				this.viewFromDate = true;
			} else if (this.selectedReportMode == 'rptModeTillDate') {
				this.viewFromDate = false;
			}

		} else if (searchBy === 'gcAmountCollected') {
			//input field hide and show starts
			this.viewReportMode = false;
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewPartyName = true;
			this.viewCollectionMan = true;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = false;
			this.searchByDate = true;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			this.hideTable();
			this.showDaily = false;
			this.clearDataTable();
			this.viewSendSms = false;
			this.gcAmountCollectedTable = true
		} else if (searchBy === 'gcLeftToParty') {
			//input field hide and show starts
			this.viewReportMode = true;
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewPartyName = true;
			this.viewCollectionMan = true;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = false;
			this.searchByDate = true;
			this.viewSource = true;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			this.showTillDate = true;
			this.showDaily = false;
			this.clearDataTable();
			this.hideTable();
			this.viewSendSms = false;
			this.gcLeftToPartyTable = true;
			this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
			if (this.selectedReportMode == 'rptModeDateWise') {
				this.viewFromDate = true;
			} else if (this.selectedReportMode == 'rptModeTillDate') {
				this.viewFromDate = false;
			}

		} else if (searchBy === 'gcLeftToPartyStmtSummary') {
			//input field hide and show starts
			this.viewReportMode = true;
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewPartyName = true;
			this.viewCollectionMan = true;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = false;
			this.searchByDate = true;
			this.viewSource = false;
			this.viewDestination = true;
			this.viewStationDestination = true;
			this.viewStationInvoice = false;
			this.hideTable();
			this.showDaily = false;
			this.clearDataTable();
			this.viewSendSms = false;
			this.showTillDate = true;
			this.gcLeftToPartyStatementSummaryTable = true;
			this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
			if (this.selectedReportMode == 'rptModeDateWise') {
				this.viewFromDate = true;
			} else if (this.selectedReportMode == 'rptModeTillDate') {
				this.viewFromDate = false;
			}


		} else if (searchBy === 'collectionConsolidatedReport') {
			//input field hide and show starts
			this.viewReportMode = false;
			this.viewFromDate = true;
			this.viewPartyName = false;
			this.viewCollectionMan = false;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = false;
			this.searchByDate = true;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			this.hideTable();
			this.showDaily = false;
			this.clearDataTable();
			this.collectionConsolidatedTable = true;
			this.viewSendSms = false;
			this.viewToDate = false;


		} else if (searchBy === 'leftToPartyPendingAcknowledgmentStmts') {
			//input field hide and show starts
			this.viewReportMode = false;
			this.viewFromDate = false;
			this.viewToDate = false;
			this.viewPartyName = true;
			this.viewCollectionMan = true;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = false;
			this.searchByDate = true;
			this.viewSource = false;
			this.viewDestination = true;
			this.viewStationDestination = false;
			this.viewStationInvoice = true;
			this.hideTable();
			this.showDaily = false;
			this.clearDataTable();
			this.viewSendSms = false;
			this.leftToPartyStatementTable = true;


		} else if (searchBy === 'cashmemoStock') {
			//input field hide and show starts
			this.viewReportMode = false;
			this.viewFromDate = false;
			this.viewToDate = false;
			this.viewPartyName = true;
			this.viewCollectionMan = true;
			this.viewAreaName = true;
			this.viewCashmemoStockOptions = true;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = true;
			this.searchByDate = false;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			this.hideTable();
			this.showDaily = false;
			this.clearDataTable();
			this.viewSendSms = false;
			this.cashmemoStockSummaryTable = true;

		} else if (searchBy === 'dailyCollection') {
			//input field hide and show starts
			this.viewReportMode = true;
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewPartyName = false;
			this.viewCollectionMan = false;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = true;
			this.searchByDate = false;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			this.hideTable();
			this.showDaily = true;
			this.showTillDate = false;
			this.clearDataTable();
			this.dailyCollectionDetailsTable = true;
			//$("#"+this.pageId+"reportMode").val('Daily');


		} else if (searchBy === 'gcAmountCollectedConfirm') {
			//input field hide and show starts
			this.viewReportMode = false;
			this.viewFromDate = true;
			this.viewToDate = true;
			this.viewPartyName = true;
			this.viewCollectionMan = true;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = false;
			this.searchByDate = true;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			this.hideTable();
			this.showDaily = false;
			this.clearDataTable();
			this.viewSendSms = false;
			this.gcAmountCollectedConfirmTable = true;
		}
		else {
			//input field hide and show starts
			this.viewReportMode = false;
			this.viewFromDate = false;
			this.viewToDate = false;
			this.viewPartyName = false;
			this.viewCollectionMan = false;
			this.viewAreaName = false;
			this.viewCashmemoStockOptions = false;
			this.viewSendSms = false;
			//input field hide and show ends
			this.searchByInvoiceNumber = false;
			this.searchByDate = false;
			this.viewSource = false;
			this.viewDestination = false;
			this.viewStationDestination = false;
			this.viewStationInvoice = false;
			this.hideTable();
			this.showDaily = false;
			this.showTillDate = false;
			this.clearDataTable();
			this.viewSendSms = false;

		}
	}
	//input field and datatable hide and show starts

	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	//for datePicker

	//for the select option with filter starts

	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter(
			item =>
				item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) === true
		);
	}
	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "destinationOnly";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}
						$("#" + this.pageId + "destination").val(this.userDataDtoReturnSession.mainStation);
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};

	getConsigneeDetailsList(destShow) {
		this.consigneeNameOptions = [];
		this.partyMasterDtoForconsignee = new PartyMasterDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
			console.log(this.selectedDestination);
		} else {
			this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
		}
		this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
		this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
		this.partyMasterDtoForconsignee.mode = "specific";
		console.log(this.partyMasterDtoForconsignee);
	}
	getConsigneeDetails = (destShow) => {
		this.getConsigneeDetailsList(destShow);

		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Consignee Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.consigneeNameOptions = [];
					this.consigneeNameTA = [];
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					$("#" + this.pageId + "consigneeName").val('All');
				} else {
					this.consigneeNameOptions = response;
					this.consigneeNameTA = [];
					$("#" + this.pageId + "consigneeName").val('');
					this.consigneeNamePartyDtoAllOption.consigneeName = "All";
					this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
					for (let i = 0; i < this.consigneeNameOptions.length; i++) {
						this.consigneeNameTA.push(this.consigneeNameOptions[i]);
					}
					this.selecDestinationForTemp = this.toSetDestForConsignee;
					if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
						this.consigneeNameTATempForDest = this.consigneeNameTA;
					}
					this.toSetDestForConsignee = '';
					this.consigneeNameTATemp = this.consigneeNameTA;

					$("#" + this.pageId + "consigneeName").val('All');

					console.log(this.consigneeNameTA, this.consigneeNameTATemp);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the consignee details", "info");
			}, () => console.log('done');
	};

	consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationFotGet = e.item;
		this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
		console.log("checking the searchBy");
		console.log(this.selectedSearchBy);
		if (this.selectedSearchBy == 'dailyCollection') {
		} else if (this.selectedSearchBy == 'gcTakenBy') {
			this.getCollectionManDetailsList(true);
			this.getConsigneeDetails(true);
		} else if (this.selectedSearchBy == 'gcLeftToParty') {
			this.getCollectionManDetailsList(true);
			this.getConsigneeDetails(true);
		} else if (this.selectedSearchBy == 'gcLeftToPartyStmtSummary') {
			this.getCollectionManDetailsList(true);
			this.getConsigneeDetails(true);
		} else if (this.selectedSearchBy == 'cashmemoStock') {
			this.getCollectionManDetailsList(true);
			this.getConsigneeDetails(true);
			this.getBranchAreaDetailsList(true);
		}


	}
	rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
		this.modelConsigneeNameId = e.item;
		this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
	}


	getDetailsForCollectionMasterRead(destShow) {
		this.userDtoCollectionMan = new UserDataDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			this.userDtoCollectionMan.branchId = this.selectedDestination.destination;
			console.log(this.selectedDestination);
		} else {
			this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
		}
		// this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;

		this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
		this.userDtoCollectionMan.status = "D";
	}

	getCollectionManDetailsList(destShow) {
		this.collectionManDataList = [];
		this.collectionManTA = [];
		this.getDetailsForCollectionMasterRead(destShow);
		this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Collection Man Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.collectionManDataList = [];
					this.collectionManTA = [];
					this.userDtoCollectionManAll.collectioMan = "All";
					this.collectionManTA.push(this.userDtoCollectionManAll);
					$("#" + this.pageId + "collectionMan").val('All');
				} else {
					console.log(response);
					this.collectionManDataList = response;
					console.log(this.collectionManDataList);
					this.collectionManTA = [];
					this.userDtoCollectionManAll.collectioMan = "All";
					this.collectionManTA.push(this.userDtoCollectionManAll);
					for (let i = 0; i < this.collectionManDataList.length; i++) {
						this.collectionManTA.push(this.collectionManDataList[i]);
					}
					this.selecDestinationForTemp = this.toSetDestForColMan;
					if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
						this.collectionManTATempForDest = this.collectionManTA;
					}
					this.toSetDestForColMan = '';
					console.log(this.collectionManTATempForDest, this.selecDestinationForTemp)
					this.collectionManTATemp = this.collectionManTA;

					$("#" + this.pageId + "collectionMan").val('All');
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Collection Man details", "info");
			}, () => console.log('done');
	};

	getMethodForBranchArea(destShow) {
		this.lrDtoBranchArea = new LRDto();
		this.selectedDestination = this.modelDestinationFotGet;
		if (destShow) {
			if (this.selectedDestination.destination == 'Chennai') {
				this.lrDtoBranchArea.mode = 'Binnymills';
			} else {
				this.lrDtoBranchArea.mode = this.selectedDestination.destination;
			}

		} else {
			this.lrDtoBranchArea.mode = this.userDataDtoReturnSession.office;
		}
		this.lrDtoBranchArea.companyId = this.userDataDtoReturnSession.companyId;
	}

	getBranchAreaDetailsList(destShow) {
		this.branchAreaDataList = [];
		this.areaNameTA = [];
		this.getMethodForBranchArea(destShow);
		this.masterReadService.getBranchAreaDetailsService(this.lrDtoBranchArea).subscribe(
			(response) => {
				if (response) {
					console.log(response);
					this.branchAreaDataList = response;
					console.log(this.branchAreaDataList);
					this.lrDtoAreaAll.godownName = "All";
					this.areaNameTA.push(this.lrDtoAreaAll);
					for (let i = 0; i < this.branchAreaDataList.length; i++) {
						this.areaNameTA.push(this.branchAreaDataList[i]);
					}
					this.selecDestinationForTemp = this.toSetDestForArea;
					if (this.selecDestinationForTemp == this.userDataDtoReturnSession.mainStation) {
						this.areaNameTempForDest = this.areaNameTA;
					}
					this.toSetDestForArea = '';
					console.log(this.areaNameTempForDest, this.selecDestinationForTemp)
					this.areaNameTATemp = this.areaNameTA;
					$("#" + this.pageId + "areaName").val('All');
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => swal({
				title: "Server Error",
				text: "Problem occur while getting Area Details",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	};

	selectTodayDate() {
		this.setTodayDateOnToDate = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
		this.setTodayDateOnFromDate = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
		$("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
		$("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
	}

	getStationInfo() {
		this.lrDtoForStation = new LRDto();
		this.lrDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForStation.mainstation = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForStation.mode = "ALL";
	}
	getStationDtails = () => {
		this.getStationInfo();
		this.masterReadService.getDestinationForLREntryService(this.lrDtoForStation).subscribe(
			(response) => {
				if (response) {
					this.stationOptions = response;
					this.lrDtoForStation = new LRDto();
					this.stationTA = [];
					//	                    console.log(this.stationOptions.length);
					for (let i = 0; i < this.stationOptions.length; i++) {
						this.stationTA.push(this.stationOptions[i]);
					}
					console.log(this.stationTA, this.stationOptions);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Station Details", "info");
			}, () => console.log('done');
	};

	clearDataTable() {
		$("#" + this.pageId + "dailyCollectionDetailsTableId").DataTable().destroy();
		this.dailyCollectionDetailsDataList = [];
		this.dtTriggerDailyCollectionDetails.next();
		$("#" + this.pageId + "gcTakenById").DataTable().destroy();
		this.gcTakenByDataList = [];

		this.cashMemoDtoForCustomPrintList = [];
		this.cashMemoDtoForCustomPrintDataList = [];
		this.cashMemoDtoForCustomPrintDataSummaryList = [];

		this.dtTriggerGcTakenBy.next();
		$("#" + this.pageId + "gcLeftToPartyId").DataTable().destroy();
		this.gcLeftToPartyDataList = [];
		this.dtTriggerGcLeftToParty.next();
		$("#" + this.pageId + "gcLeftToPartyStatementSummaryId").DataTable().destroy();
		this.gcLeftToPartyStatementSummaryDataList = [];
		this.dtTriggerGcLeftToPartyStatementSummary.next();

		$("#" + this.pageId + "gcAmountCollectedId").DataTable().destroy();
		this.gcAmountCollectedDataList = [];
		this.dtTriggerGcAmountCollected.next();

		if (this.gcAmountCollectedDaysDiffView) {
			$("#" + this.pageId + "gcAmountCollectedIdDaysDiff").DataTable().destroy();
			this.gcAmountCollectedDataListDaysDiff = [];
			this.dtTriggerGcAmountCollectedDaysDiff.next();
		}

		$("#" + this.pageId + "leftToPartyStatementId").DataTable().destroy();
		this.leftToPartyStatementDataList = [];
		this.dtTriggerLeftToPartyStatement.next();

		$("#" + this.pageId + "collectionConsolidatedId").DataTable().destroy();
		this.collectionConsolidatedReportDataList = [];
		this.dtTriggerCollectionConsolidatedReport.next();

		$("#" + this.pageId + "cashmmemostockSummaryId").DataTable().destroy();
		this.cashmemoStockSummaryDataList = [];
		this.dtTriggerCashmemoStockSummary.next();

		$("#" + this.pageId + "cashmemoStockDetailsId").DataTable().destroy();
		this.cashmemoStockDetailsDataList = [];
		this.dtTriggerCashmemoStockDetails.next();

		$("#" + this.pageId + "cashmemoStockPartyWiseId").DataTable().destroy();
		this.cashmemoStockPartyWiseDataList = [];
		this.dtTriggerCashmemoStockPartyWise.next();

		$("#" + this.pageId + "gcAmountCollectedConfirmId").DataTable().destroy();
		this.gcAmountCollectedConfirmDataList = [];
		this.dtTriggerGcAmountCollectedConfirm.next();

	}
	searchMethod() {

		this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
		console.log("checking the searchByMethod");
		console.log(this.selectedSearchBy);
		this.selectedSearchByStock = $("#" + this.pageId + "searchWith").val();
		//this.clearDataTable();
		if (this.selectedSearchBy == 'dailyCollection') {
			this.searchByDailyCollection();
		} else if (this.selectedSearchBy == 'gcTakenBy') {
			this.searchByGCTaken();
		} else if (this.selectedSearchBy == 'gcLeftToParty') {
			this.searchByGCLeftToParty();
		} else if (this.selectedSearchBy == 'gcLeftToPartyStmtSummary') {
			this.searchByGCLeftToPartyStmtSummary();
		} else if (this.selectedSearchBy == 'gcAmountCollected') {
			this.searchByGCAmountCollected();
		} else if (this.selectedSearchBy == 'leftToPartyPendingAcknowledgmentStmts') {
			this.searchByLeftToPartyPendingAcknowledgmentStmts();
		} else if (this.selectedSearchBy == 'collectionConsolidatedReport') {
			this.searchByCollectionConsolidated();
		} else if (this.selectedSearchBy == 'cashmemoStock') {
			if (this.selectedSearchByStock == 'summary') {
				this.searchByCashMemoStockSummaryDetails();
			} else if (this.selectedSearchByStock == 'detail') {
				this.searchByCashMemoStockDetails();
			} else if (this.selectedSearchByStock == 'partyWise') {
				this.searchByCashMemoStockPartyWiseDetails();
			}
		} else if (this.selectedSearchBy == 'gcAmountCollectedConfirm') {
			this.searchByGCAmountCollectedConfirm();
		}
	}
	//For Daily Collecition  Search Wise
	searchByDailyCollection() {
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedSmsNo = $("#" + this.pageId + "smsNo").val();

		this.cashmemoDtoForDailyCollectionSearchDetails = new CashMemoDto();

		if (this.selectedReportMode == 'rptModeDateWise') {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForDailyCollectionSearchDetails.fromdate = this.selectedFromDate;
				this.cashmemoDtoForDailyCollectionSearchDetails.todate = this.selectedToDate;
			}
		} else if (this.selectedReportMode == 'rptModeDaily') {
			if (this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForDailyCollectionSearchDetails.fromdate = this.selectedToDate;
				this.cashmemoDtoForDailyCollectionSearchDetails.todate = this.selectedToDate;
			}

		}
		this.cashmemoDtoForDailyCollectionSearchDetails.status = 'completed';
		this.cashmemoDtoForDailyCollectionSearchDetails.mode = 'rpt';
		this.cashmemoDtoForDailyCollectionSearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		this.cashmemoDtoForDailyCollectionSearchDetails.destination = this.userDataDtoReturnSession.mainStation;
		console.log(this.cashmemoDtoForDailyCollectionSearchDetails);

		this.searchByDailyCollectionDetails();

	}
	searchByDailyCollectionDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getDailyCollectionRptDetails(this.cashmemoDtoForDailyCollectionSearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "dailyCollectionDetailsTableId").DataTable().destroy();
				this.dailyCollectionDetailsDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.dailyCollectionDetailsDataList = response;
					console.log(this.dailyCollectionDetailsDataList);
				}
				this.dtTriggerDailyCollectionDetails.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Daily Collection details", "info");
			}, () => console.log('done');
	};
	//For GC taken Search Wise
	searchByGCTaken() {
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedSmsNo = $("#" + this.pageId + "smsNo").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();

		this.cashmemoDtoForGCTakenSearchDetails = new CashMemoDto();


		if (this.selectedReportMode == 'rptModeDateWise') {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForGCTakenSearchDetails.fromdate = this.selectedFromDate;
				this.cashmemoDtoForGCTakenSearchDetails.todate = this.selectedToDate;
				this.cashmemoDtoForGCTakenSearchDetails.tillDateMode = "false";
			}
		} else if (this.selectedReportMode == 'rptModeTillDate') {
			if (this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForGCTakenSearchDetails.fromdate = this.selectedToDate;
				this.cashmemoDtoForGCTakenSearchDetails.todate = this.selectedToDate;
				this.cashmemoDtoForGCTakenSearchDetails.tillDateMode = "true";
			}

		}
		this.cashmemoDtoForGCTakenSearchDetails.reportMode = 'All';
		this.cashmemoDtoForGCTakenSearchDetails.mode = 'taken';
		this.cashmemoDtoForGCTakenSearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		//For Office
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoForGCTakenSearchDetails.office = this.selectedDestination;
			} else {
				this.cashmemoDtoForGCTakenSearchDetails.office = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoForGCTakenSearchDetails.office = this.userDataDtoReturnSession.mainStation;
		}

		//For CollectionMan 
		if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
			this.cashmemoDtoForGCTakenSearchDetails.collectionMan = this.selectedCollectionMan;
		} else {
			this.cashmemoDtoForGCTakenSearchDetails.collectionMan = 'All';
		}
		//For Consignee 
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.cashmemoDtoForGCTakenSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCTakenSearchDetails.consigneeName = this.selectedConsigneeName;
		} else {
			this.cashmemoDtoForGCTakenSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCTakenSearchDetails.consigneeName = 'All';
		}
		console.log(this.cashmemoDtoForGCTakenSearchDetails);

		this.searchByGCTakenDetails();

	}
	searchByGCTakenDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionDetailsGCTaken(this.cashmemoDtoForGCTakenSearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "gcTakenById").DataTable().destroy();
				this.gcTakenByDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					localStorage.clear();
					this.gcTakenByDataList = response;
					console.log(this.gcTakenByDataList);

					//	this.setPringForGCTakenReport();
				}
				this.dtTriggerGcTakenBy.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the GC Taken Details", "info");
			}, () => console.log('done');
	};


	setPringForGCTakenReport() {
		localStorage.clear();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		if (this.selectedCollectionMan == null || this.selectedCollectionMan == '' || this.selectedCollectionMan == 'All') {
			swal({
				title: "Warning",
				text: "No Collection Man Selected !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else if (this.gcTakenByDataList == null || this.gcTakenByDataList == '') {
			swal({
				title: "Warning",
				text: "No records found !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			this.cashMemoDtoForGCTakenPrint = new CashMemoDto();
			this.cashMemoDtoForGCTakenPrint.collectionMan = this.selectedCollectionMan
			console.log(this.cashMemoDtoForGCTakenPrint);

			localStorage.setItem('printAfterAssignMemoFromCollectionManRpt', JSON.stringify(this.cashMemoDtoForGCTakenPrint));
			localStorage.setItem('printAfterAssignMemolistFromCollectionManRpt', JSON.stringify(this.gcTakenByDataList));
			this.viewGCTakenPrint = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewGCTakenPrint) {
					this.viewGCTakenPrint = false;
					this.cashMemoDtoForGCTakenPrint = new CashMemoDto();
					localStorage.clear();
				}
			});
		}


	}

	//For GC Left To Party Search Wise
	searchByGCLeftToParty() {
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();

		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();

		this.cashmemoDtoForGCLeftToPartySearchDetails = new CashMemoDto();


		if (this.selectedReportMode == 'rptModeDateWise') {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForGCLeftToPartySearchDetails.fromdate = this.selectedFromDate;
				this.cashmemoDtoForGCLeftToPartySearchDetails.todate = this.selectedToDate;
				this.cashmemoDtoForGCLeftToPartySearchDetails.tillDateMode = "false";
			}
		} else if (this.selectedReportMode == 'rptModeTillDate') {
			if (this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForGCLeftToPartySearchDetails.fromdate = this.selectedToDate;
				this.cashmemoDtoForGCLeftToPartySearchDetails.todate = this.selectedToDate;
				this.cashmemoDtoForGCLeftToPartySearchDetails.tillDateMode = "true";
			}

		}
		this.cashmemoDtoForGCLeftToPartySearchDetails.reportMode = 'All';
		this.cashmemoDtoForGCLeftToPartySearchDetails.mode = 'left';
		this.cashmemoDtoForGCLeftToPartySearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		//For Office
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoForGCLeftToPartySearchDetails.office = this.selectedDestination;
			} else {
				this.cashmemoDtoForGCLeftToPartySearchDetails.office = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoForGCLeftToPartySearchDetails.office = this.userDataDtoReturnSession.mainStation;
		}

		//For CollectionMan 
		if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
			this.cashmemoDtoForGCLeftToPartySearchDetails.collectionMan = this.selectedCollectionMan;
		} else {
			this.cashmemoDtoForGCLeftToPartySearchDetails.collectionMan = 'All';
		}
		//For Consignee 
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.cashmemoDtoForGCLeftToPartySearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCLeftToPartySearchDetails.consigneeName = this.selectedConsigneeName;
		} else {
			this.cashmemoDtoForGCLeftToPartySearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCLeftToPartySearchDetails.consigneeName = 'All';
		}
		console.log(this.cashmemoDtoForGCLeftToPartySearchDetails);

		this.searchByGCLeftToPartyDetails();

	}
	searchByGCLeftToPartyDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionDetailsLeftToParty(this.cashmemoDtoForGCLeftToPartySearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "gcLeftToPartyId").DataTable().destroy();
				this.gcLeftToPartyDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.gcLeftToPartyDataList = response;
					console.log(this.gcLeftToPartyDataList);
				}
				this.dtTriggerGcLeftToParty.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the GC Left To Party Details", "info");
			}, () => console.log('done');
	};

	getImgForCpy1(gcLeftToPartyData) {

		if (gcLeftToPartyData.imgURLStmCpy == null || gcLeftToPartyData.imgURLStmCpy == undefined || gcLeftToPartyData.imgURLStmCpy == 'NA') {

		} else {
			window.open(gcLeftToPartyData.imgURLStmCpy, '_blank');
		}
	}

	getImgForCpy2(gcLeftToPartyData) {

		if (gcLeftToPartyData.imgURLStmCpy2 == null || gcLeftToPartyData.imgURLStmCpy2 == undefined || gcLeftToPartyData.imgURLStmCpy2 == 'NA') {

		} else {
			window.open(gcLeftToPartyData.imgURLStmCpy2, '_blank');
		}
	}

	getImgForCpy3(gcLeftToPartyData) {

		if (gcLeftToPartyData.imgURLStmCpy3 == null || gcLeftToPartyData.imgURLStmCpy3 == undefined || gcLeftToPartyData.imgURLStmCpy3 == 'NA') {

		} else {
			window.open(gcLeftToPartyData.imgURLStmCpy3, '_blank');
		}
	}

	setPringForGCLeftToPartyReport() {
		localStorage.clear();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		if (this.selectedCollectionMan == null || this.selectedCollectionMan == '' || this.selectedCollectionMan == 'All') {
			swal({
				title: "Warning",
				text: "No Collection Man Selected !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else if (this.gcLeftToPartyDataList == null || this.gcLeftToPartyDataList == '') {
			swal({
				title: "Warning",
				text: "No records found !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			this.cashMemoDtoForGCLeftToPartyPrint = new CashMemoDto();
			this.cashMemoDtoForGCLeftToPartyPrint.collectionMan = this.selectedCollectionMan;
			console.log(this.cashMemoDtoForGCLeftToPartyPrint);

			localStorage.setItem('printGCLeftToPartyFromCollectionManRpt', JSON.stringify(this.cashMemoDtoForGCLeftToPartyPrint));
			localStorage.setItem('printGCLeftToPartyMemolistFromCollectionManRpt', JSON.stringify(this.gcLeftToPartyDataList));
			this.viewGCLeftToPartyPrint = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewGCLeftToPartyPrint) {
					this.viewGCLeftToPartyPrint = false;
					this.cashMemoDtoForGCLeftToPartyPrint = new CashMemoDto();
					localStorage.clear();
				}
			});
		}
	}
	//For GC Left To Party Stmt Summary Search Wise
	searchByGCLeftToPartyStmtSummary() {
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails = new CashMemoDto();
		if (this.selectedReportMode == 'rptModeDateWise') {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "From Date , To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.fromdate = this.selectedFromDate;
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.todate = this.selectedToDate;
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.tillDateMode = "false";
			}
		} else if (this.selectedReportMode == 'rptModeTillDate') {
			if (this.selectedToDate == null || this.selectedToDate == '') {
				swal({
					title: "Mandatory Field",
					text: "To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			} else {
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.fromdate = this.selectedToDate;
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.todate = this.selectedToDate;
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.tillDateMode = "true";
			}

		}
		this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.reportMode = 'All';
		this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.mode = 'LeftToPartyStmSum';
		this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		//For Office
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.office = this.selectedDestination;
			} else {
				this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.office = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.office = this.userDataDtoReturnSession.mainStation;
		}

		//For CollectionMan 
		if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
			this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.collectionMan = this.selectedCollectionMan;
		} else {
			this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.collectionMan = 'All';
		}
		//For Consignee 
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.consigneeName = this.selectedConsigneeName;
		} else {
			this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails.consigneeName = 'All';
		}
		console.log(this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails);

		this.searchByGCLeftToPartyStmtSummaryDetails();

	}
	searchByGCLeftToPartyStmtSummaryDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionDetailsLeftToPartyStmtSummary(this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "gcLeftToPartyStatementSummaryId").DataTable().destroy();
				this.gcLeftToPartyStatementSummaryDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.gcLeftToPartyStatementSummaryDataList = response;
					console.log(this.gcLeftToPartyStatementSummaryDataList);
				}
				this.dtTriggerGcLeftToPartyStatementSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the GC Left To Party Stmt Summary Details", "info");
			}, () => console.log('done');
	};

	getImgForLeftToPartStmtSumm(gcLeftToPartyStatementSummaryData) {

		if (gcLeftToPartyStatementSummaryData.imgURLStmCpy == null || gcLeftToPartyStatementSummaryData.imgURLStmCpy == undefined || gcLeftToPartyStatementSummaryData.imgURLStmCpy == 'NA') {

		} else {
			window.open(gcLeftToPartyStatementSummaryData.imgURLStmCpy, '_blank');
		}
	}


	//For GC Amount Collected Search Wise
	searchByGCAmountCollected() {
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.enteredDiffDays = $("#" + this.pageId + "diffDays").val();

		this.cashmemoDtoForGCAmountCollectedSearchDetails = new CashMemoDto();
		if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
			swal({
				title: "Mandatory Field",
				text: "From Date , To Date Is Mandatory Fields",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.fromdate = this.selectedFromDate;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.todate = this.selectedToDate;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.tillDateMode = "false";
		}

		this.cashmemoDtoForGCAmountCollectedSearchDetails.reportMode = 'All';
		this.cashmemoDtoForGCAmountCollectedSearchDetails.mode = 'amt';
		this.cashmemoDtoForGCAmountCollectedSearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		//For Office
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoForGCAmountCollectedSearchDetails.office = this.selectedDestination;
			} else {
				this.cashmemoDtoForGCAmountCollectedSearchDetails.office = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.office = this.userDataDtoReturnSession.mainStation;
		}

		//For CollectionMan 
		if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.collectionMan = this.selectedCollectionMan;
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.collectionMan = 'All';
		}
		//For Consignee 
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeName = this.selectedConsigneeName;
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeName = 'All';
		}
		//for srd stock-collection diff dates
		if (this.enteredDiffDays != null && this.enteredDiffDays != '' && this.enteredDiffDays != undefined) {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.daysDiff = this.enteredDiffDays;
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.daysDiff = null;
		}

		console.log(this.cashmemoDtoForGCAmountCollectedSearchDetails);

		this.searchByGCAmountCollectedDetails();

	}
	searchByGCAmountCollectedDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionDetailsAmtCollected(this.cashmemoDtoForGCAmountCollectedSearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "gcAmountCollectedId").DataTable().destroy();
				if (this.gcAmountCollectedDaysDiffView) {
					$("#" + this.pageId + "gcAmountCollectedIdDaysDiff").DataTable().destroy();
				}
				this.gcAmountCollectedDataList = [];
				this.gcAmountCollectedDataListDaysDiff = [];
				this.gcAmountCollectedDataList = response.consigneeWiseMemo;
				this.gcAmountCollectedDataListDaysDiff = response.consigneeWiseBalanceDtails;
				console.log(this.gcAmountCollectedDataList);
				console.log(this.gcAmountCollectedDataListDaysDiff);

				if (this.gcAmountCollectedDaysDiffView) {
					if (response.consigneeWiseMemo.length == 0 && response.consigneeWiseBalanceDtails.length == 0) {
						swal({
							title: "Warning",
							text: "No Details found !",
							icon: "warning",
							closeOnClickOutside: false,
							closeOnEsc: false,
						});

					}
				} else {
					if (response.consigneeWiseMemo.length == 0) {
						swal({
							title: "Warning",
							text: "No Details found !",
							icon: "warning",
							closeOnClickOutside: false,
							closeOnEsc: false,
						});

					}
				}

				this.dtTriggerGcAmountCollected.next();
				if (this.gcAmountCollectedDaysDiffView) {
					this.dtTriggerGcAmountCollectedDaysDiff.next();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the GC Amount Collected Details", "info");
			}, () => console.log('done');
	};

	//For GC Amount Collected Confirm Search Wise
	searchByGCAmountCollectedConfirm() {
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.cashmemoDtoForGCAmountCollectedSearchDetails = new CashMemoDto();
		if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
			swal({
				title: "Mandatory Field",
				text: "From Date , To Date Is Mandatory Fields",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.fromdate = this.selectedFromDate;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.todate = this.selectedToDate;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.tillDateMode = "false";
		}

		this.cashmemoDtoForGCAmountCollectedSearchDetails.reportMode = 'All';
		this.cashmemoDtoForGCAmountCollectedSearchDetails.mode = 'amt';
		this.cashmemoDtoForGCAmountCollectedSearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		//For Office
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoForGCAmountCollectedSearchDetails.office = this.selectedDestination;
			} else {
				this.cashmemoDtoForGCAmountCollectedSearchDetails.office = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.office = this.userDataDtoReturnSession.mainStation;
		}

		//For CollectionMan 
		if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.collectionMan = this.selectedCollectionMan;
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.collectionMan = 'All';
		}
		//For Consignee 
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeName = this.selectedConsigneeName;
		} else {
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoForGCAmountCollectedSearchDetails.consigneeName = 'All';
		}
		console.log(this.cashmemoDtoForGCAmountCollectedSearchDetails);

		this.searchByGCAmountCollectedConfirmDetails();

	}

	searchByGCAmountCollectedConfirmDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionDetailsAmtCollectedConfirm(this.cashmemoDtoForGCAmountCollectedSearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "gcAmountCollectedConfirmId").DataTable().destroy();
				this.gcAmountCollectedConfirmDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.gcAmountCollectedConfirmDataList = response;
					console.log(this.gcAmountCollectedConfirmDataList);
				}
				this.dtTriggerGcAmountCollectedConfirm.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the GC Amount Collected Confirm Details", "info");
			}, () => console.log('done');
	};

	//For Left To Party Statement  Pending Ack Stmts Search Wise
	searchByLeftToPartyPendingAcknowledgmentStmts() {
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails = new CashMemoDto();


		this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.tillDateMode = "false";
		this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.reportMode = 'All';
		this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.mode = 'tpStmt';
		this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.status = 'Pending';
		this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		//For Office
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.office = this.selectedDestination;
			} else {
				this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.office = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.office = this.userDataDtoReturnSession.mainStation;
		}

		//For CollectionMan 

		if (this.pageMode != null && ((this.pageMode == 'ack') || (this.pageMode == 'sb'))) {
			this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.collectionMan = this.collectionManSelected;
		} else {
			if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined) {
				this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.collectionMan = this.selectedCollectionMan;
			} else {
				this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.collectionMan = 'All';
			}
		}

		//For Consignee 
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.consigneeName = this.selectedConsigneeName;
		} else {
			this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails.consigneeName = 'All';
		}
		console.log(this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails);

		this.searchByLeftToPartyPendingAcknowledgmentStmtsDetails();

	}
	searchByLeftToPartyPendingAcknowledgmentStmtsDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionDetailsLeftToPartyPendAckStmt(this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "leftToPartyStatementId").DataTable().destroy();
				this.leftToPartyStatementDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.leftToPartyStatementDataList = response;
					console.log(this.leftToPartyStatementDataList);
				}
				this.dtTriggerLeftToPartyStatement.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Left To Party Statement  Pending Ack Stmts  Details", "info");
			}, () => console.log('done');
	};

	///Upload Process Starts From here
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	setAckRec(leftToPartyStatementData, leftToPartyStmtPopUpTemplate) {
		this.ackReckPopupStmtNo = '';
		if (leftToPartyStatementData.statementNo != null && leftToPartyStatementData.statementNo != '') {
			swal({
				title: "Confirm Received",
				text: "Sure U Want To Confirm the Acknowledgement received",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((statusBackMemo) => {
				if (statusBackMemo) {
					this.ackReckPopupStmtNo = leftToPartyStatementData.statementNo;
					this.modalRefferenceLeftToPartyStmtPopUp = this.modalService.open(leftToPartyStmtPopUpTemplate,
						{ centered: true });
					this.modalRefferenceLeftToPartyStmtPopUp.result.then((result) => {
						this.modalRefferenceLeftToPartyStmtClosePopUp = `Closed with: ${result}`;
					}, reason => {
						this.modalRefferenceLeftToPartyStmtClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
					});
				}

			});
		} else {

			swal({
				title: "Warning",
				text: "No Details found !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});

		}
	}


	onFileChangedLeftToPartyStmt1(event) {
		this.selectedLeftToPartyStmt1Details = event.target.files[0];
		console.log(this.selectedLeftToPartyStmt1Details);
	}
	onFileChangedLeftToPartyStmt2(event) {
		this.selectedLeftToPartyStmt2Details = event.target.files[0];
		console.log(this.selectedLeftToPartyStmt2Details);
	}
	onFileChangedLeftToPartyStmt3(event) {
		this.selectedLeftToPartyStmt3Details = event.target.files[0];
		console.log(this.selectedLeftToPartyStmt3Details);
	}

	uploadLeftToPartyStmtBtnPopUp() {
		console.log($("#" + this.pageId + "leftToPartyStmtFile1Upload").val());
		console.log($("#" + this.pageId + "leftToPartyStmtFile2Upload").val());
		console.log($("#" + this.pageId + "leftToPartyStmtFile3Upload").val());


		if ($("#" + this.pageId + "leftToPartyStmtFile1Upload").val() != null && $("#" + this.pageId + "leftToPartyStmtFile1Upload").val() != "") {
			this.showSpinnerForActionForUpload = true;
			if ((this.selectedLeftToPartyStmt1Details.type != null &&
				(this.selectedLeftToPartyStmt1Details.type == "image/jpeg" ||
					this.selectedLeftToPartyStmt1Details.type == "image/jpg" ||
					this.selectedLeftToPartyStmt1Details.type == "image/png"))) {
				var splitTypeToPartyStmt1 = this.selectedLeftToPartyStmt1Details.type.split("/");

				const commonListToPartyStmt1 = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeToPartyStmt1[1] + "&&" + this.ackReckPopupStmtNo + "";
				console.log("commonListToPartyStmt1");
				console.log(commonListToPartyStmt1);
				const formData = new FormData();
				console.log("commonListToPartyStmt1, this.selectedLeftToPartyStmt1Details, this.selectedLeftToPartyStmt1Details.name >> 1");
				console.log(commonListToPartyStmt1, this.selectedLeftToPartyStmt1Details, this.selectedLeftToPartyStmt1Details.name);

				formData.append(commonListToPartyStmt1, commonListToPartyStmt1);
				formData.append('myfileToPartyStmt1', this.selectedLeftToPartyStmt1Details, this.selectedLeftToPartyStmt1Details.name);
				console.log("formData >> 1");
				console.log(formData);
				this.masterReadService.uploadFile(formData).subscribe(
					(response) => {
						if (response) {
							console.log(response);
							this.showSpinnerForActionForUpload = false;
							if (response[0] != "Error") {
								swal("File Upload", "File Uploaded Succcessfully", "success");
								this.isUploaded = true;
							} else {
								swal("ERROR", "Correct the File and Upload Again1", "error");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForActionForUpload = false;
						swal("Error", "Server Error While Uploading File", "info");
					}, () => console.log('done');
			} else {
				this.showSpinnerForActionForUpload = false;
				swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
			}
		}


		if ($("#" + this.pageId + "leftToPartyStmtFile2Upload").val() != null && $("#" + this.pageId + "leftToPartyStmtFile2Upload").val() != "") {
			this.showSpinnerForActionForUpload = true;
			if ((this.selectedLeftToPartyStmt2Details.type != null &&
				(this.selectedLeftToPartyStmt2Details.type == "image/jpeg" ||
					this.selectedLeftToPartyStmt2Details.type == "image/jpg" ||
					this.selectedLeftToPartyStmt2Details.type == "image/png"))) {
				var splitTypeToPartyStmt2 = this.selectedLeftToPartyStmt2Details.type.split("/");

				const commonListToPartyStmt2 = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeToPartyStmt2[1] + "&&" + this.ackReckPopupStmtNo + "";
				const formData = new FormData();
				formData.append(commonListToPartyStmt2, commonListToPartyStmt2);
				formData.append('myfileToPartyStmt2', this.selectedLeftToPartyStmt2Details, this.selectedLeftToPartyStmt2Details.name);
				this.masterReadService.uploadFile(formData).subscribe(
					(response) => {
						if (response) {
							this.showSpinnerForActionForUpload = false;
							console.log(response);
							if (response[0] != "Error") {
								swal("File Upload", "File Uploaded Succcessfully", "success");
								this.isUploaded = true;
							} else {
								swal("ERROR", "Correct the File and Upload Again 2", "error");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForActionForUpload = false;
						swal("Error", "Server Error While Uploading File", "info");
					}, () => console.log('done');
			} else {
				this.showSpinnerForActionForUpload = false;
				swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
			}
		}
		if ($("#" + this.pageId + "leftToPartyStmtFile3Upload").val() != null && $("#" + this.pageId + "leftToPartyStmtFile3Upload").val() != "") {
			this.showSpinnerForActionForUpload = true;
			if ((this.selectedLeftToPartyStmt3Details.type != null &&
				(this.selectedLeftToPartyStmt3Details.type == "image/jpeg" ||
					this.selectedLeftToPartyStmt3Details.type == "image/jpg" ||
					this.selectedLeftToPartyStmt3Details.type == "image/png"))) {
				var splitTypeToPartyStmt3 = this.selectedLeftToPartyStmt3Details.type.split("/");

				const commonListToPartyStmt3 = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeToPartyStmt3[1] + "&&" + this.ackReckPopupStmtNo + "";
				const formData = new FormData();
				formData.append(commonListToPartyStmt3, commonListToPartyStmt3);
				formData.append('myfileToPartyStmt3', this.selectedLeftToPartyStmt3Details, this.selectedLeftToPartyStmt3Details.name);
				this.masterReadService.uploadFile(formData).subscribe(
					(response) => {
						if (response) {
							console.log(response);
							this.showSpinnerForActionForUpload = false;
							if (response[0] != "Error") {
								swal("File Upload", "File Uploaded Succcessfully", "success");
								this.isUploaded = true;
							} else {
								swal("ERROR", "Correct the File and Upload Again 3", "error");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForActionForUpload = false;
						swal("Error", "Server Error While Uploading File", "info");
					}, () => console.log('done');
			} else {
				this.showSpinnerForActionForUpload = false;
				swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
			}
		}

	}

	applyLeftToPartyStmtBtnPopUp() {
		this.updateAckRecDetails();
	}

	closeLeftToPartyStmtBtnPopUp() {
		this.modalRefferenceLeftToPartyStmtPopUp.close();
		this.isUploaded = false;
		this.ackReckPopupStmtNo = '';
		$("#" + this.pageId + "leftToPartyStmtFile1Upload").val('');
		$("#" + this.pageId + "leftToPartyStmtFile2Upload").val('');
		$("#" + this.pageId + "leftToPartyStmtFile3Upload").val('');


	}
	updateAckRecDetails() {

		this.cashMemoDtoForGCLeftToPartyAckRecUpdate = new CashMemoDto();
		this.cashMemoDtoForGCLeftToPartyAckRecUpdate.statementNo = this.ackReckPopupStmtNo;
		this.isUploaded = true;
		//for Copy1
		if (this.isUploaded == true) {
			if ($("#" + this.pageId + "leftToPartyStmtFile1Upload").val() != null && $("#" + this.pageId + "leftToPartyStmtFile1Upload").val() != "") {
				const constImgUrlsStmt1 = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + this.ackReckPopupStmtNo + "_" + this.selectedLeftToPartyStmt1Details.name + "";
				this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgURLStmCpy = constImgUrlsStmt1;
				this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgUploadedStmCpy = true;
			}


		} else {
			this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgUploadedStmCpy = false;
		}
		//for Copy2
		if (this.isUploaded == true) {
			if ($("#" + this.pageId + "leftToPartyStmtFile2Upload").val() != null && $("#" + this.pageId + "leftToPartyStmtFile2Upload").val() != "") {
				const constImgUrlsStmt2 = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + this.ackReckPopupStmtNo + "_" + this.selectedLeftToPartyStmt2Details.name + "";
				this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgURLStmCpy2 = constImgUrlsStmt2;
				this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgUploadedStmCpy2 = true;
			}

		} else {
			this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgUploadedStmCpy2 = false;

		}
		//for Copy3
		if (this.isUploaded == true) {
			if ($("#" + this.pageId + "leftToPartyStmtFile3Upload").val() != null && $("#" + this.pageId + "leftToPartyStmtFile3Upload").val() != "") {
				const constImgUrlsStmt3 = "http://vehicledriverdocs.s3.amazonaws.com/documents/" + this.ackReckPopupStmtNo + "_" + this.selectedLeftToPartyStmt3Details.name + "";
				this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgURLStmCpy3 = constImgUrlsStmt3;
				this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgUploadedStmCpy3 = true;
			}
		} else {
			this.cashMemoDtoForGCLeftToPartyAckRecUpdate.imgUploadedStmCpy3 = false;

		}
		this.showSpinnerForAction = true;
		console.log(this.cashMemoDtoForGCLeftToPartyAckRecUpdate);
		this.updateAckRecDetailsMethod();
	}
	updateAckRecDetailsMethod = () => {

		this.memoReport.updateToPartyAckRec(this.cashMemoDtoForGCLeftToPartyAckRecUpdate).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status == 'failed') {
					swal({
						title: "Error",
						text: "Problem occurred while updating the acknowledgment details",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else if (response.status == 'success') {
					swal({
						title: "Success",
						text: "Acknowledgment details updated",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				}
				this.modalRefferenceLeftToPartyStmtPopUp.close();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Problem occurred while updating the acknowledgment details", "info");
			}, () => console.log('done');
	};


	//For  Collection Consolidated Search Wise
	searchByCollectionConsolidated() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.cashmemoDtoForColletionConsolidatedSearchDetails = new CashMemoDto();
		if (this.selectedFromDate == null || this.selectedFromDate == '') {
			swal({
				title: "Mandatory Field",
				text: "From Date Is Mandatory Fields",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		} else {
			this.cashmemoDtoForColletionConsolidatedSearchDetails.fromdate = this.selectedFromDate;
			this.cashmemoDtoForColletionConsolidatedSearchDetails.tillDateMode = "false";
		}

		this.cashmemoDtoForColletionConsolidatedSearchDetails.reportMode = 'All';
		this.cashmemoDtoForColletionConsolidatedSearchDetails.mode = 'consol';
		this.cashmemoDtoForColletionConsolidatedSearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		//For Office
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoForColletionConsolidatedSearchDetails.office = this.selectedDestination;
			} else {
				this.cashmemoDtoForColletionConsolidatedSearchDetails.office = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoForColletionConsolidatedSearchDetails.office = this.userDataDtoReturnSession.mainStation;
		}

		console.log(this.cashmemoDtoForColletionConsolidatedSearchDetails);

		this.searchByCollectionConsolidatedDetails();

	}
	searchByCollectionConsolidatedDetails = () => {
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionDetailsCollectionConsolidatedRpt(this.cashmemoDtoForColletionConsolidatedSearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "collectionConsolidatedId").DataTable().destroy();
				this.collectionConsolidatedReportDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.collectionConsolidatedReportDataList = response;
					console.log(this.collectionConsolidatedReportDataList);
				}
				this.dtTriggerCollectionConsolidatedReport.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Collection Consolidated  Details", "info");
			}, () => console.log('done');
	};
	//For  Cashmemo Stock summary Search Wise
	searchByCashMemoStockSummary() {
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
		this.selectedAreaName = $("#" + this.pageId + "areaName").val();
		this.cashmemoDtoCashMemoStockSummarySearchDetails = new CashMemoDto();
		this.cashmemoDtoCashMemoStockSummarySearchDetails.reportMode = 'summary';
		this.cashmemoDtoCashMemoStockSummarySearchDetails.mode = 'tillDate';
		this.cashmemoDtoCashMemoStockSummarySearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		this.cashmemoDtoCashMemoStockSummarySearchDetails.todate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
		this.listCollectionMan = [];
		this.listArea = [];
		//For destination
		if (this.destView == true) {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != undefined) {
				this.cashmemoDtoCashMemoStockSummarySearchDetails.destination = this.selectedDestination;
			} else {
				this.cashmemoDtoCashMemoStockSummarySearchDetails.destination = this.userDataDtoReturnSession.mainStation;
			}
		} else {
			this.cashmemoDtoCashMemoStockSummarySearchDetails.destination = this.userDataDtoReturnSession.mainStation;
		}

		//For CollectionMan 
		if (this.selectedCollectionMan != null && this.selectedCollectionMan != '' && this.selectedCollectionMan != undefined && this.selectedCollectionMan != 'All') {
			this.cashmemoDtoCashMemoStockSummarySearchDetails.collectionMan = this.selectedCollectionMan;
			this.listCollectionMan.push(this.selectedCollectionMan);
			this.cashmemoDtoCashMemoStockSummarySearchDetails.list = this.listCollectionMan;
		} else {
			this.cashmemoDtoCashMemoStockSummarySearchDetails.collectionMan = 'All';
			for (let i = 0; i < this.collectionManDataList.length; i++) {
				console.log(this.collectionManDataList[i].collectioMan);
				this.listCollectionMan.push(this.collectionManDataList[i].collectioMan);
			}
			this.cashmemoDtoCashMemoStockSummarySearchDetails.list = this.listCollectionMan;
		}

		//For Consignee 
		if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
			this.cashmemoDtoCashMemoStockSummarySearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoCashMemoStockSummarySearchDetails.consigneeName = this.selectedConsigneeName;
		} else {
			this.cashmemoDtoCashMemoStockSummarySearchDetails.consigneeId = this.selectedConsigneeId;
			this.cashmemoDtoCashMemoStockSummarySearchDetails.consigneeName = 'All';
		}
		//For Area 
		if (this.selectedAreaName != null && this.selectedAreaName != '' && this.selectedAreaName != 'All') {
			this.cashmemoDtoCashMemoStockSummarySearchDetails.area = this.selectedAreaName;
			this.listArea.push(this.selectedAreaName);
			this.cashmemoDtoCashMemoStockSummarySearchDetails.list2 = this.listArea;
		} else {
			//alert('area:'+this.branchAreaDataList.length);
			this.cashmemoDtoCashMemoStockSummarySearchDetails.area = 'All';
			for (let i = 0; i < this.branchAreaDataList.length; i++) {
				this.listArea.push(this.branchAreaDataList[i].godownName);
				console.log(this.branchAreaDataList[i].godownName);
			}
			this.cashmemoDtoCashMemoStockSummarySearchDetails.list2 = this.listArea;
		}



		//this.searchByCashMemoStockSummaryDetails();

	}
	searchByCashMemoStockSummaryDetails = () => {
		this.searchByCashMemoStockSummary();
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionManWiseOSDetailsSummary(this.cashmemoDtoCashMemoStockSummarySearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "cashmmemostockSummaryId").DataTable().destroy();
				this.cashmemoStockSummaryDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.cashmemoStockSummaryDataList = response;
					console.log(this.cashmemoStockSummaryDataList);
				}
				this.dtTriggerCashmemoStockSummary.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Cashmemo Stock Summary", "info");
			}, () => console.log('done');
	};

	searchByCashMemoStockDetails = () => {
		this.searchByCashMemoStockSummary();
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionManWiseOSDetailsService(this.cashmemoDtoCashMemoStockSummarySearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "cashmemoStockDetailsId").DataTable().destroy();
				this.cashmemoStockDetailsDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.cashmemoStockDetailsDataList = response;
				}
				this.dtTriggerCashmemoStockDetails.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Cashmemo Stock Details", "info");
			}, () => console.log('done');
	};

	searchByCashMemoStockPartyWiseDetails = () => {
		this.searchByCashMemoStockSummary();
		this.showSpinnerForAction = true;
		this.memoReport.getCollectionManWiseOSPartyWiseDetails(this.cashmemoDtoCashMemoStockSummarySearchDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "cashmemoStockPartyWiseId").DataTable().destroy();
				this.cashmemoStockPartyWiseDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.cashmemoStockPartyWiseDataList = response;
				}
				this.dtTriggerCashmemoStockPartyWise.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Party wise Cashmemo Stock Details", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		this.isUploaded = false;
		this.ackReckPopupStmtNo = '';
		$("#" + this.pageId + "leftToPartyStmtFile1Upload").val('');
		$("#" + this.pageId + "leftToPartyStmtFile2Upload").val('');
		$("#" + this.pageId + "leftToPartyStmtFile3Upload").val('');
		this.hideTable();
		this.viewReportMode = true;
		this.viewFromDate = true;
		this.viewToDate = true;
		this.viewPartyName = true;
		this.viewCollectionMan = true;
		this.viewAreaName = false;
		this.viewCashmemoStockOptions = false;
		this.viewSendSms = false;
		//input field hide and show ends
		this.searchByInvoiceNumber = true;
		this.searchByDate = false;
		this.viewSource = false;
		this.viewDestination = false;
		this.viewStationDestination = false;
		this.viewStationInvoice = false;
		// this.dailyCollectionDetailsTable=false;
		this.hideTable();
		this.showTillDate = true;
		this.showDaily = false;
		this.clearDataTable();
		this.gcTakenByTable = true;
		this.viewSendSms = false;
		$("#" + this.pageId + "searchBy").val('gcTakenBy');
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		if (this.selectedReportMode == 'rptModeDateWise') {
			this.viewFromDate = true;
		} else if (this.selectedReportMode == 'rptModeTillDate') {
			this.viewFromDate = false;
		}
		this.showSpinnerForAction = false;
		this.modelConsigneeNameId = '';
		this.modelAreaName = '';
		this.selectedConsigneeId = '';
		this.cashmemoDtoForDailyCollectionSearchDetails = new CashMemoDto();
		this.cashmemoDtoForGCTakenSearchDetails = new CashMemoDto();
		this.viewGCTakenPrint = false;
		this.viewCustomPrintV1 = false;
		this.cashMemoDtoForGCTakenPrint = new CashMemoDto();
		this.cashMemoDtoForCustomPrint = new CashMemoDto();
		localStorage.clear();
		this.cashmemoDtoForGCLeftToPartySearchDetails = new CashMemoDto();
		this.viewGCLeftToPartyPrint = false;
		this.cashMemoDtoForGCLeftToPartyPrint = new CashMemoDto();
		this.selectedReportMode = '';
		$("#" + this.pageId + "smsNo").val('');
		this.selectedFromDate = '';
		this.selectedToDate = '';
		this.selectedDestination = '';
		this.selectedCollectionMan = '';
		this.selectedConsigneeName = '';
		this.cashmemoDtoForGCLeftToPartyStmtSummarySearchDetails = new CashMemoDto();
		this.cashmemoDtoForGCAmountCollectedSearchDetails = new CashMemoDto();
		this.cashmemoDtoLeftToPartyPendingAcknowledgmentStmtsSearchDetails = new CashMemoDto();
		this.cashMemoDtoForGCLeftToPartyAckRecUpdate = new CashMemoDto();
		this.cashmemoDtoForColletionConsolidatedSearchDetails = new CashMemoDto();
		this.cashmemoDtoCashMemoStockSummarySearchDetails = new CashMemoDto();

	}

	rowCheckBoxClicked(e, gcAmountCollectedConfirmData) {
		if (e.currentTarget.checked) {
			this.cashmemoDtoAmtCollectedConfirmChecked = new CashMemoDto();
			this.cashmemoDtoAmtCollectedConfirmChecked.lrNumber = gcAmountCollectedConfirmData.lrNumber;
			this.cashmemoDtoAmtCollectedConfirmChecked.memoNumber = gcAmountCollectedConfirmData.memoNumber;
			this.cashmemoDtoAmtCollectedConfirmChecked.userName = this.userDataDtoReturnSession.userId;
			this.cashmemoDtoAmtCollectedConfirmCheckedList.push(this.cashmemoDtoAmtCollectedConfirmChecked);
		} else {
			let index = this.cashmemoDtoAmtCollectedConfirmCheckedList.findIndex(x => x.memoNumber === gcAmountCollectedConfirmData.memoNumber);
			this.cashmemoDtoAmtCollectedConfirmCheckedList.splice(index, 1);
		}
		console.log('list : ' + this.cashmemoDtoAmtCollectedConfirmCheckedList)
		this.changeDetectorRef.detectChanges();
	}

	checkuncheckallCollConfirm() {
		if (this.gcAmountCollectedConfirmDataList.length == 0) {
			swal("Not Allowed", "No data to select!", "warning");
			return false;
		} else {
			if (this.colmrIsChecked == true) {
				this.colmrIsChecked = false;
				this.cashmemoDtoAmtCollectedConfirmCheckedList = [];
			} else {
				this.colmrIsChecked = true;
				this.cashmemoDtoAmtCollectedConfirmCheckedList = [];
				for (let i = 0; i < this.gcAmountCollectedConfirmDataList.length; i++) {
					this.cashmemoDtoAmtCollectedConfirmChecked = new CashMemoDto();
					this.cashmemoDtoAmtCollectedConfirmChecked.lrNumber = this.gcAmountCollectedConfirmDataList[i].lrNumber;
					this.cashmemoDtoAmtCollectedConfirmChecked.memoNumber = this.gcAmountCollectedConfirmDataList[i].memoNumber;
					this.cashmemoDtoAmtCollectedConfirmChecked.userName = this.userDataDtoReturnSession.userId;
					this.cashmemoDtoAmtCollectedConfirmCheckedList.push(this.cashmemoDtoAmtCollectedConfirmChecked);
				}
			}
		}
		this.changeDetectorRef.detectChanges();
	}

	amtCollectedConfirm() {
		if (this.cashmemoDtoAmtCollectedConfirmCheckedList.length == 0) {
			swal("Not Allowed", "No memo selected to Confirm!", "warning");
		} else {
			swal({
				title: "Confirmation",
				text: "Sure you want to confirm the collection for the selected Memos?",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((yesBtn) => {
				if (yesBtn) {
					this.amtCollectedConfirmService();
				}
			})
		}
	}

	amtCollectedConfirmService() {
		this.showSpinnerForAction = true;
		this.memoService.updateTempCashMemoComplete(this.cashmemoDtoAmtCollectedConfirmCheckedList).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					this.cashmemoDtoAmtCollectedConfirmUpdateRet = response;
					if (this.cashmemoDtoAmtCollectedConfirmUpdateRet.status == "Success") {
						swal("Success", "Selected memos completed successfully!", "info");
						//PFCHG_V1
						if (this.sendCollectionCloseWhatsappUpdates) {
							this.whatsAppFlowForCollectionClose();
						} else {
							this.cashmemoDtoAmtCollectedConfirmCheckedList = [];
						}
						this.searchByGCAmountCollectedConfirmDetails();
					} else if (this.cashmemoDtoAmtCollectedConfirmUpdateRet.status == "Failed") {
						swal("Failed", "Server Problem Occurred While updating the Details", "error");
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While updating the Details", "error");
			},
			() => console.log('done');
	}

	customPrintGcAmtCollected() {
		if (this.gcAmountCollectedDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			/*this.cashMemoDtoForCustomPrintListColumnNames = ["Memo No", "Memo Date", "Memo Amt", "Less", "TDS", "Claim",
				"Chq Amt", "NEFT Amt", "Cash", "Amt Collected", "Ref No", "LR No", "Party Name", "Remark", "Art", "Area"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [13, 5, 6, 3, 3, 5, 5, 6, 5, 7, 6, 8, 9, 7, 4, 8]*/
			this.cashMemoDtoForCustomPrintListColumnNames = ["Memo No", "Memo Dt", "Memo Amt", "Less", "TDS", "Claim",
				"Chq Amt", "NEFT Amt", "Cash", "Debit A/c", "UPI Amt", "Amt Collected", "Ref No", "LR No", "Party Name", "Art"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [14, 9, 6, 4, 4, 5, 5, 6, 5, 5, 6, 7, 6, 8, 7, 3]
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
				console.log(this.cashMemoDtoForCustomPrintList);
			}

			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			this.memoAmountSummary = this.lessSummary = this.tdsSummary = this.claimSummary = this.chqAmtSummary = this.neftAmtSummary = this.cashAmtSummary = this.amtCollectedSummary = this.articleSummary = 0, this.debitAcAmtSummary = 0, this.upiAmtSummary = 0;
			for (let i = 0; i < this.gcAmountCollectedDataList.length; i++) {

				/*this.cashMemoDtoForCustomPrintListColumnValues = [this.gcAmountCollectedDataList[i].memoNumber, this.gcAmountCollectedDataList[i].memoDateStr,
				this.gcAmountCollectedDataList[i].amount, this.gcAmountCollectedDataList[i].less, this.gcAmountCollectedDataList[i].tds,
				this.gcAmountCollectedDataList[i].claim, this.gcAmountCollectedDataList[i].chequeAmt,
				this.gcAmountCollectedDataList[i].neftAmt, this.gcAmountCollectedDataList[i].cashAmt, this.gcAmountCollectedDataList[i].amountCollected,
				this.gcAmountCollectedDataList[i].chqNumber, this.gcAmountCollectedDataList[i].lrNumber, this.gcAmountCollectedDataList[i].consigneeName,
				this.gcAmountCollectedDataList[i].remarks, this.gcAmountCollectedDataList[i].article, this.gcAmountCollectedDataList[i].area];*/
				this.cashMemoDtoForCustomPrintListColumnValues = [this.gcAmountCollectedDataList[i].memoNumber, this.gcAmountCollectedDataList[i].memoDateStr,
				this.gcAmountCollectedDataList[i].amount, this.gcAmountCollectedDataList[i].less, this.gcAmountCollectedDataList[i].tds,
				this.gcAmountCollectedDataList[i].claim, this.gcAmountCollectedDataList[i].chequeAmt,
				this.gcAmountCollectedDataList[i].neftAmt, this.gcAmountCollectedDataList[i].cashAmt, this.gcAmountCollectedDataList[i].debitAcAmt, this.gcAmountCollectedDataList[i].upiAmt, this.gcAmountCollectedDataList[i].amountCollected,
				this.gcAmountCollectedDataList[i].chqNumber, this.gcAmountCollectedDataList[i].lrNumber, this.gcAmountCollectedDataList[i].consigneeName,
				this.gcAmountCollectedDataList[i].article];
				///////
				this.memoAmountSummary = +this.memoAmountSummary + this.gcAmountCollectedDataList[i].amount;
				this.lessSummary = +this.lessSummary + this.gcAmountCollectedDataList[i].less;
				this.tdsSummary = +this.tdsSummary + this.gcAmountCollectedDataList[i].tds;
				this.claimSummary = +this.claimSummary + this.gcAmountCollectedDataList[i].claim;
				this.chqAmtSummary = +this.chqAmtSummary + this.gcAmountCollectedDataList[i].chequeAmt;
				this.neftAmtSummary = +this.neftAmtSummary + this.gcAmountCollectedDataList[i].neftAmt;
				this.cashAmtSummary = +this.cashAmtSummary + this.gcAmountCollectedDataList[i].cashAmt;
				this.debitAcAmtSummary = +this.debitAcAmtSummary + this.gcAmountCollectedDataList[i].debitAcAmt;
				this.upiAmtSummary = +this.upiAmtSummary + this.gcAmountCollectedDataList[i].upiAmt;
				this.amtCollectedSummary = +this.amtCollectedSummary + this.gcAmountCollectedDataList[i].amountCollected;
				this.articleSummary = +this.articleSummary + this.gcAmountCollectedDataList[i].article;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			}
			/*this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.gcAmountCollectedDataList.length, "", this.memoAmountSummary, this.lessSummary,
			this.tdsSummary, this.claimSummary, this.chqAmtSummary, this.neftAmtSummary, this.cashAmtSummary, this.amtCollectedSummary, "", "", "", "", this.articleSummary, ,];*/

			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.gcAmountCollectedDataList.length, "", this.memoAmountSummary, this.lessSummary,
			this.tdsSummary, this.claimSummary, this.chqAmtSummary, this.neftAmtSummary, this.chqAmtSummary, this.debitAcAmtSummary, this.upiAmtSummary, this.amtCollectedSummary, "", "", "", this.articleSummary];
			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "G.C Amount Collected By" + " : " + this.selectedCollectionMan);
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
	customPrintGcTakenBy() {
		if (this.gcTakenByDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Memo No", "Memo Dt(A)", "Assign Dt(B)", "Diff(B-A)", "Memo Amot", "LR No", "Party Name"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 15, 15, 13, 15, 12, 15];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			this.memoAmountSummary = 0;
			for (let i = 0; i < this.gcTakenByDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.gcTakenByDataList[i].memoNumber, this.gcTakenByDataList[i].memoDateStr,
				this.gcTakenByDataList[i].assignedDateStr, this.gcTakenByDataList[i].days, this.gcTakenByDataList[i].amount, this.gcTakenByDataList[i].lrNumber, this.gcTakenByDataList[i].consigneeName];
				/////////
				this.memoAmountSummary = +this.memoAmountSummary + this.gcTakenByDataList[i].amount;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.gcTakenByDataList.length, "", "", "", this.memoAmountSummary, "", ""];
			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingV3 = [];
			this.cashMemoDtoForCustomPrintListHeadingV4 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Received the above number of G.C for collection amounting to Rs." + this.memoAmountSummary + ". I will render the account in the evening"];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["Signature"];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
				this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "G.C Taken By" + " : " + this.selectedCollectionMan);
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});



		}
	}
	customPrintGcLeftToParty() {

		if (this.gcLeftToPartyDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Memo No", "Memo Dt(A)", "Assigned Dt(B)", "Diff(CurDt-B)", "Memo Amt", "LR No", "Consignee Name", "To Party Stmt No"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 12, 12, 10, 10, 12, 15, 14];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.memoAmountSummary = 0;


			for (let i = 0; i < this.gcLeftToPartyDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.gcLeftToPartyDataList[i].memoNumber, this.gcLeftToPartyDataList[i].memoDateStr, this.gcLeftToPartyDataList[i].assignedDateStr, this.gcLeftToPartyDataList[i].days
					, this.gcLeftToPartyDataList[i].amount, this.gcLeftToPartyDataList[i].lrNumber, this.gcLeftToPartyDataList[i].consigneeName, this.gcLeftToPartyDataList[i].statementNo];
				/////
				this.memoAmountSummary = +this.memoAmountSummary + this.gcLeftToPartyDataList[i].amount;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.gcLeftToPartyDataList.length, "", "", "", this.memoAmountSummary, "", "", ""];
			}

			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "G.C Left To Party" + " : " + this.selectedCollectionMan);
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintGcLeftToPartyStmtSum() {
		if (this.gcLeftToPartyStatementSummaryDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Statement No", "Stmt Dt(A)", "Diff(CurDt-A)", "Memo No", "Memo Amt", "Collection Man", "Consignee Name"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 12, 13, 8, 12, 15, 25];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.totalMemoAmountSummary = 0;
			for (let i = 0; i < this.gcLeftToPartyStatementSummaryDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.gcLeftToPartyStatementSummaryDataList[i].statementNo, this.gcLeftToPartyStatementSummaryDataList[i].stmtDateStr, this.gcLeftToPartyStatementSummaryDataList[i].daysDiff, this.gcLeftToPartyStatementSummaryDataList[i].totalMemos
					, this.gcLeftToPartyStatementSummaryDataList[i].grandTotal, this.gcLeftToPartyStatementSummaryDataList[i].collectionMan, this.gcLeftToPartyStatementSummaryDataList[i].consigneeName,];
				this.totalMemoAmountSummary = +this.totalMemoAmountSummary + this.gcLeftToPartyStatementSummaryDataList[i].grandTotal;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.gcLeftToPartyStatementSummaryDataList.length, "", "", "", this.totalMemoAmountSummary, "", ""];
			}


			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}


			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "G.C Left To Party Stmt Sum" + " : " + this.selectedCollectionMan);
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintCollectionConRpt() {
		if (this.collectionConsolidatedReportDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Name", "G.C. Out", "G.C. Amount", "G.C. In", "G.C Amount", "G.C. Balance", "G.C Amount"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [28, 15, 9, 15, 9, 9, 15];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.gcOutSummary = this.gcAmountSummary1 = this.gcInSummary = this.gcAmountSummary2 = this.gcBalanceSummary = this.gcAmountSummary3 = 0;
			for (let i = 0; i < this.collectionConsolidatedReportDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.collectionConsolidatedReportDataList[i].collectionMan, this.collectionConsolidatedReportDataList[i].capacity, this.collectionConsolidatedReportDataList[i].amount
					, this.collectionConsolidatedReportDataList[i].memoBack, this.collectionConsolidatedReportDataList[i].memoBackAmt, this.collectionConsolidatedReportDataList[i].capacityGCIn, this.collectionConsolidatedReportDataList[i].amountGCIn];
				//
				this.gcOutSummary = +this.gcOutSummary + this.collectionConsolidatedReportDataList[i].capacity;
				this.gcAmountSummary1 = +this.gcAmountSummary1 + this.collectionConsolidatedReportDataList[i].amount;
				this.gcInSummary = +this.gcInSummary + this.collectionConsolidatedReportDataList[i].memoBack;
				this.gcAmountSummary2 = +this.gcAmountSummary2 + this.collectionConsolidatedReportDataList[i].memoBackAmt;
				this.gcBalanceSummary = +this.gcBalanceSummary + this.collectionConsolidatedReportDataList[i].capacityGCIn;
				this.gcAmountSummary3 = +this.gcAmountSummary3 + this.collectionConsolidatedReportDataList[i].amountGCIn;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.collectionConsolidatedReportDataList.length, this.gcOutSummary, this.gcAmountSummary1, this.gcInSummary, this.gcAmountSummary2, this.gcBalanceSummary, this.gcAmountSummary3];
			}


			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			// localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Collection Consolidate Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintLeftToPartyPndAchStmt() {
		if (this.leftToPartyStatementDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Statement No", "Statement Date", "Pending Memos", "Pending Memo Amount", "Left To Party", "Colllection Man"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 14, 12, 15, 22, 22]
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.pndMemosSummary = this.pendingMemoAmtSummary = 0;
			for (let i = 0; i < this.leftToPartyStatementDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.leftToPartyStatementDataList[i].statementNo, this.leftToPartyStatementDataList[i].stmtDateStr, this.leftToPartyStatementDataList[i].totalMemos
					, this.leftToPartyStatementDataList[i].grandTotal, this.leftToPartyStatementDataList[i].consigneeName, this.leftToPartyStatementDataList[i].collectionMan];
				this.pndMemosSummary = +this.pndMemosSummary + this.leftToPartyStatementDataList[i].totalMemos;
				this.pendingMemoAmtSummary = +this.pendingMemoAmtSummary + this.leftToPartyStatementDataList[i].grandTotal;

				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.leftToPartyStatementDataList.length, "", this.pndMemosSummary, this.pendingMemoAmtSummary, "", ""];
			}


			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Left To Party Pnd Ack Stmnt" + " : " + this.selectedCollectionMan);

			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintCashMemoStockSummary() {
		if (this.cashmemoStockSummaryDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Collection Man", "Total Memo", "Total Amt", "Article", "Area", "Tot.Stock Lrs", "Tot.Stock Amt", "Tot.Assigned Lrs", "Tot.Assigned Amt", "Tot.To-Party Lrs", "Tot.To-Party Amt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 6, 7, 6, 8, 7, 9, 11, 11, 10, 10];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.totalMemoSummary = this.totalAmtSummary = this.articleSummary = this.totalStockLrsSummary = this.totalStockAmtSummary = this.totalAssignedLrsSummary = this.totalAssignedAmtSummary = this.totalToPartyLrsSummary = this.totalToPartyAmtSummary = 0;
			for (let i = 0; i < this.cashmemoStockSummaryDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.cashmemoStockSummaryDataList[i].collectionMan, this.cashmemoStockSummaryDataList[i].totalMemos == null ? 0 : this.cashmemoStockSummaryDataList[i].totalMemos, this.cashmemoStockSummaryDataList[i].grandTotal == null ? 0 : this.cashmemoStockSummaryDataList[i].grandTotal,
				this.cashmemoStockSummaryDataList[i].totalArticles == null ? 0 : this.cashmemoStockSummaryDataList[i].totalArticles, this.cashmemoStockSummaryDataList[i].area == null ? "NA" : this.cashmemoStockSummaryDataList[i].area, this.cashmemoStockSummaryDataList[i].pendingMemos == null ? 0 : this.cashmemoStockSummaryDataList[i].pendingMemos, this.cashmemoStockSummaryDataList[i].pendingMemoTotal == null ? 0 : this.cashmemoStockSummaryDataList[i].pendingMemoTotal,
				this.cashmemoStockSummaryDataList[i].assignedMemos == null ? 0 : this.cashmemoStockSummaryDataList[i].assignedMemos, this.cashmemoStockSummaryDataList[i].assignedMemoTotal == null ? 0 : this.cashmemoStockSummaryDataList[i].assignedMemoTotal, this.cashmemoStockSummaryDataList[i].toPartyMemos == null ? 0 : this.cashmemoStockSummaryDataList[i].toPartyMemos, this.cashmemoStockSummaryDataList[i].toPartyMemoTotal == null ? 0 : this.cashmemoStockSummaryDataList[i].toPartyMemoTotal];
				////
				this.totalMemoSummary = +this.totalMemoSummary + this.cashmemoStockSummaryDataList[i].totalMemos;
				this.totalAmtSummary = +this.totalAmtSummary + this.cashmemoStockSummaryDataList[i].grandTotal;
				this.articleSummary = +this.articleSummary + this.cashmemoStockSummaryDataList[i].totalArticles;
				this.totalStockLrsSummary = +this.totalStockLrsSummary + this.cashmemoStockSummaryDataList[i].pendingMemos;
				this.totalStockAmtSummary = +this.totalStockAmtSummary + this.cashmemoStockSummaryDataList[i].pendingMemoTotal;
				this.totalAssignedLrsSummary = +this.totalAssignedLrsSummary + this.cashmemoStockSummaryDataList[i].assignedMemos;
				this.totalAssignedAmtSummary = +this.totalAssignedAmtSummary + this.cashmemoStockSummaryDataList[i].assignedMemoTotal;
				this.totalToPartyLrsSummary = +this.totalToPartyLrsSummary + this.cashmemoStockSummaryDataList[i].toPartyMemos;
				this.totalToPartyAmtSummary = +this.totalToPartyAmtSummary + this.cashmemoStockSummaryDataList[i].toPartyMemoTotal;

				this.cashMemoDtoForCustomPrintData = new CashMemoDto();

				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			}

			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.cashmemoStockSummaryDataList.length, this.totalMemoSummary, this.totalAmtSummary, this.articleSummary, "", this.totalStockLrsSummary, this.totalStockAmtSummary, this.totalAssignedLrsSummary, this.totalAssignedAmtSummary, this.totalToPartyLrsSummary, this.totalToPartyAmtSummary];
			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Cash Memo Stock Summary Report");

			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}


	customPrintCashMemoStockDetails() {
		if (this.cashmemoStockDetailsDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Consignee Name", "Memo No", "LR No", "Amount", "Bkg Dt", "Memo Dt", "Articles", "Area", "Collection Man", "Status", "Cur Dt-Memo Dt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [12, 8, 8, 8, 8, 10, 5, 8, 10, 13, 10];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.totalAmtSummary = this.articleSummary = 0;
			for (let i = 0; i < this.cashmemoStockDetailsDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.cashmemoStockDetailsDataList[i].consigneeName,
				this.cashmemoStockDetailsDataList[i].memoNumber,
				this.cashmemoStockDetailsDataList[i].lrNumber,
				this.cashmemoStockDetailsDataList[i].amount,
				this.cashmemoStockDetailsDataList[i].bookingDateStr,
				this.cashmemoStockDetailsDataList[i].memoDateStr,
				this.cashmemoStockDetailsDataList[i].article,
				this.cashmemoStockDetailsDataList[i].area,
				this.cashmemoStockDetailsDataList[i].collectionMan,
				this.cashmemoStockDetailsDataList[i].status,
				this.cashmemoStockDetailsDataList[i].days];

				this.cashMemoDtoForCustomPrintData = new CashMemoDto();

				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.totalAmtSummary = +this.totalAmtSummary + this.cashmemoStockDetailsDataList[i].amount;
				this.articleSummary = +this.articleSummary + this.cashmemoStockDetailsDataList[i].article;
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.cashmemoStockDetailsDataList.length, "", "", this.totalAmtSummary, "", "", this.articleSummary, "", "", "", ""];
			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			// localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Cash Memo Stock Details Report");

			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintCashMemoStockPartyWise() {
		if (this.cashmemoStockPartyWiseDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Consignee Name", "Total Memo", "Total Amount", "Articles", "Area", "Collection Man"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [20, 10, 20, 15, 15, 20];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.totalMemoSummary = this.totalAmtSummary = this.articleSummary = 0;
			for (let i = 0; i < this.cashmemoStockPartyWiseDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.cashmemoStockPartyWiseDataList[i].consigneeName,
				this.cashmemoStockPartyWiseDataList[i].totalMemos,
				this.cashmemoStockPartyWiseDataList[i].grandTotal,
				this.cashmemoStockPartyWiseDataList[i].totalArticles,
				this.cashmemoStockPartyWiseDataList[i].area,
				this.cashmemoStockPartyWiseDataList[i].collectionMan];

				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.totalMemoSummary = +this.totalMemoSummary + this.cashmemoStockPartyWiseDataList[i].totalMemos;
				this.totalAmtSummary = +this.totalAmtSummary + this.cashmemoStockPartyWiseDataList[i].grandTotal;
				this.articleSummary = +this.articleSummary + this.cashmemoStockPartyWiseDataList[i].totalArticles;
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.cashmemoStockPartyWiseDataList.length, this.totalMemoSummary, this.totalAmtSummary, this.articleSummary, "", ""];
			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			// localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Cash Memo Stock Party Wise Report");

			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintDailyCollection() {
		if (this.dailyCollectionDetailsDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Collection No", "Collection Man", "No Of G.C.", "Total Amount", "Less", "TDS Less", "Claim Less", "Amount Collected", "Cash Amount", "Cheque Amount", "NEFT/IMPS Amount", "Debit A/c Amount", "UPI Amount"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [9, 13, 7, 8, 6, 6, 6, 7, 7, 7, 9, 8, 7];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.noOfgcSummary = this.totalAmtSummary = this.lessSummary = this.tdsLessSummary = this.claimLessSummary = this.amtCollectedSummary = this.cashAmtSummary = this.chqAmtSummary = this.neftImpsSummary = 0, this.debitAcAmtSummary = 0, this.upiAmtSummary = 0;
			for (let i = 0; i < this.dailyCollectionDetailsDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.dailyCollectionDetailsDataList[i].size, this.dailyCollectionDetailsDataList[i].collectionMan, this.dailyCollectionDetailsDataList[i].totalMemos
					, this.dailyCollectionDetailsDataList[i].grandTotal, this.dailyCollectionDetailsDataList[i].less, this.dailyCollectionDetailsDataList[i].tds, this.dailyCollectionDetailsDataList[i].claim,
				this.dailyCollectionDetailsDataList[i].pdcAmt, this.dailyCollectionDetailsDataList[i].cashAmt, this.dailyCollectionDetailsDataList[i].chequeAmt, this.dailyCollectionDetailsDataList[i].neftAmt, this.dailyCollectionDetailsDataList[i].debitAcAmt, this.dailyCollectionDetailsDataList[i].upiAmt];
				this.noOfgcSummary = this.noOfgcSummary + this.dailyCollectionDetailsDataList[i].totalMemos;
				this.totalAmtSummary = this.totalAmtSummary + this.dailyCollectionDetailsDataList[i].grandTotal;
				this.lessSummary = this.lessSummary + this.dailyCollectionDetailsDataList[i].less;
				this.tdsLessSummary = this.tdsLessSummary + this.dailyCollectionDetailsDataList[i].tds;
				this.claimLessSummary = this.claimLessSummary + this.dailyCollectionDetailsDataList[i].claim;
				this.amtCollectedSummary = this.amtCollectedSummary + this.dailyCollectionDetailsDataList[i].pdcAmt;
				this.cashAmtSummary = this.cashAmtSummary + this.dailyCollectionDetailsDataList[i].cashAmt;
				this.chqAmtSummary = this.chqAmtSummary + this.dailyCollectionDetailsDataList[i].chequeAmt;
				this.neftImpsSummary = this.neftImpsSummary + this.dailyCollectionDetailsDataList[i].neftAmt;
				this.debitAcAmtSummary = this.debitAcAmtSummary + this.dailyCollectionDetailsDataList[i].debitAcAmt;
				this.upiAmtSummary = this.upiAmtSummary + this.dailyCollectionDetailsDataList[i].upiAmt;

				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.dailyCollectionDetailsDataList.length, "", this.noOfgcSummary, this.totalAmtSummary, this.lessSummary, this.tdsLessSummary, this.claimLessSummary, this.amtCollectedSummary, this.cashAmtSummary, this.chqAmtSummary, this.neftImpsSummary, this.debitAcAmtSummary, this.upiAmtSummary];
			}


			//heading logics For Date
			this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["As On"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}



			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			// localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Daily Collection Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}


	confirmSendSMS() {
		swal({
			title: "Confirmation",
			text: "Sure you want to send the SMS?",
			icon: "info",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((yesBtn) => {
			if (yesBtn) {
				this.sendSMSMethod();
			}
		})
	}

	sendSMSMethod() {
		this.selectedReportMode = $("#" + this.pageId + "reportMode").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedSmsNo = $("#" + this.pageId + "smsNo").val();

		this.cashMemoDtoForSms = new CashMemoDto();
		this.cashMemoDtoForSms.fromdate = this.selectedToDate;
		this.cashMemoDtoForSms.todate = this.selectedToDate;
		this.cashMemoDtoForSms.status = 'completed';
		this.cashMemoDtoForSms.mode = 'sms';
		this.cashMemoDtoForSms.companyId = this.userDataDtoReturnSession.companyId;
		this.cashMemoDtoForSms.destination = this.userDataDtoReturnSession.mainStation;
		this.cashMemoDtoForSms.mainStation = this.userDataDtoReturnSession.mainStation;
		this.cashMemoDtoForSms.contactNumber = this.selectedSmsNo;

		this.showSpinnerForAction = true;
		this.memoReport.dailyCollectionReportPrepareSMS(this.cashMemoDtoForSms).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status == "send") {
					swal("Success", "SMS sent to the entered mobile numbers.", "info");
				} else if (response.status == "notsend") {
					swal("Failed", "Failed to send the SMS", "error");
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Failed", "Server problem occurred while sending the SMS!", "error");
			}, () => console.log('done');
	};

	//
	gotoLeftToPartyPopUPLinkData(leftToPartyStatementData, printPreviewStmtPopUpPopUpTemplate) {
		console.log(leftToPartyStatementData);
		this.printToSentStmtNo = null;
		this.printToSentStmtNo = leftToPartyStatementData.statementNo;

		this.modalRefferenceLeftToPartyStmtPopUp = this.modalService.open(printPreviewStmtPopUpPopUpTemplate,
			{ centered: true, size: "lg" });
		this.modalRefferenceLeftToPartyStmtPopUp.result.then((result) => {
			this.modalRefferenceLeftToPartyStmtClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceLeftToPartyStmtClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});

		var leftToPartyStatementDataLocalStorage: any;
		leftToPartyStatementDataLocalStorage = leftToPartyStatementData;
		localStorage.clear();
		localStorage.setItem('leftToPartyGeneralDetailsLocalStorage',
			JSON.stringify(leftToPartyStatementDataLocalStorage));

		this.viewPrintPopupLeftToParty = true;
		window.addEventListener('afterprint', (onclick) => {
			if (this.viewPrintPopupLeftToParty) {
				this.viewPrintPopupLeftToParty = false;
				localStorage.clear();
			}
		});
	}

	closePopUpBtn() {
		this.modalRefferenceLeftToPartyStmtPopUp.close();
	}

	printPopUpBtn() {
		this.modalRefferenceLeftToPartyStmtPopUp.close();
		localStorage.clear();
		localStorage.setItem('leftToPartyCustomizePrintShowLocalStorage',
			JSON.stringify(this.printToSentStmtNo));
		this.viewLeftToPartyCustomPrintV1 = true;
		window.addEventListener('afterprint', (onclick) => {
			if (this.viewLeftToPartyCustomPrintV1) {
				this.viewLeftToPartyCustomPrintV1 = false;
				localStorage.clear();
			}
		});
	};
	gcTakenPrintByGroupingConsignee() {
		console.log(this.gcTakenByDataList);
		if (this.gcTakenByDataList.length == 0) {
			swal({
				title: "Warning",
				text: "No Data To Print !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});

		} else {
			this.cashMemoDtoForGCTaken = new CashMemoDto();
			this.cashMemoDtoForGCTaken.collectionMan = this.selectedCollectionMan;
			console.log(this.cashMemoDtoForGCTaken);
			localStorage.setItem('gcTakenGroupingRptPrintLoaclStorage',
				JSON.stringify(this.gcTakenByDataList));
			localStorage.setItem('printGCTakenFromCollectionManRpt', JSON.stringify(this.cashMemoDtoForGCTaken));
			console.log("am in by gc taken rpt");
			this.viewGcTakenGroupingByConsigneeRptPrint = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewGcTakenGroupingByConsigneeRptPrint) {
					this.viewGcTakenGroupingByConsigneeRptPrint = false;
					this.cashMemoDtoForGCTaken = new CashMemoDto();
					localStorage.clear();
				}
			});
		}
	};

	setGCLeftToPartyReportGroupingPrint() {
		localStorage.clear();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		if (this.selectedCollectionMan == null || this.selectedCollectionMan == '' || this.selectedCollectionMan == 'All') {
			swal({
				title: "Warning",
				text: "No Collection Man Selected !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else if (this.gcLeftToPartyDataList == null || this.gcLeftToPartyDataList == '') {
			swal({
				title: "Warning",
				text: "No records found !",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			this.cashMemoDtoForGCLeftToPartyGrpPrint = new CashMemoDto();
			this.cashMemoDtoForGCLeftToPartyGrpPrint.collectionMan = this.selectedCollectionMan;
			console.log(this.cashMemoDtoForGCLeftToPartyGrpPrint);

			localStorage.setItem('printGCLeftToPartyFromCollectionManRptFroGrpPrint', JSON.stringify(this.cashMemoDtoForGCLeftToPartyGrpPrint));
			localStorage.setItem('printGCLeftToPartyMemolistFromCollectionManRptFroGrpPrint', JSON.stringify(this.gcLeftToPartyDataList));
			this.viewGcTakenLeftToPartyGroupingByConsigneeRptPrint = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewGcTakenLeftToPartyGroupingByConsigneeRptPrint) {
					this.viewGcTakenLeftToPartyGroupingByConsigneeRptPrint = false;
					this.cashMemoDtoForGCLeftToPartyGrpPrint = new CashMemoDto();
					localStorage.clear();
				}
			});
		}
	}


	customPrintPaymentChallan(dailyCollectionDetailsData) {
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.cashmemoDtoForDailyCollectionSearchDetails = new CashMemoDto();
		this.cashmemoDtoForDailyCollectionSearchDetails.fromdate = this.selectedToDate;
		this.cashmemoDtoForDailyCollectionSearchDetails.todate = this.selectedToDate;
		this.cashmemoDtoForDailyCollectionSearchDetails.status = 'completed';
		this.cashmemoDtoForDailyCollectionSearchDetails.mode = 'rpt';
		this.cashmemoDtoForDailyCollectionSearchDetails.companyId = this.userDataDtoReturnSession.companyId;
		this.cashmemoDtoForDailyCollectionSearchDetails.destination = this.userDataDtoReturnSession.mainStation;
		this.cashmemoDtoForDailyCollectionSearchDetails.size = dailyCollectionDetailsData.size;
		this.cashmemoDtoForDailyCollectionSearchDetails.collectionMan = dailyCollectionDetailsData.collectionMan;
		console.log("this.cashmemoDtoForDailyCollectionSearchDetails");
		console.log(this.cashmemoDtoForDailyCollectionSearchDetails);

		this.showSpinnerForAction = true;
		this.memoReport.getDailyCollectionDetailsPrint(this.cashmemoDtoForDailyCollectionSearchDetails).subscribe(
			(response) => {
				console.log("response");
				console.log(response);
				this.showSpinnerForAction = false;
				if (response.status == "success") {
					console.log("dailyCollectionDetailsData");
					console.log(dailyCollectionDetailsData);
					localStorage.clear();
					this.cashMemoDtoForPaymentChallanPrint = new CashMemoDto();
					this.cashMemoDtoForPaymentChallanPrint = response;
					this.cashMemoDtoForPaymentChallanPrint.toDate = this.selectedToDate;
					this.cashMemoDtoForPaymentChallanPrint.size = dailyCollectionDetailsData.size;
					this.cashMemoDtoForPaymentChallanPrint.collectionMan = dailyCollectionDetailsData.collectionMan;

					localStorage.setItem('printPaymentFollowupChallanRpt', JSON.stringify(this.cashMemoDtoForPaymentChallanPrint));
					this.viewPaymentChallanPrint = true;
					window.addEventListener('afterprint', (onclick) => {
						if (this.viewPaymentChallanPrint) {
							this.viewPaymentChallanPrint = false;
							localStorage.clear();
						}
					});
					this.changeDetectorRef.detectChanges();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Payment Followup Challan", "info");
			}, () => console.log('done');
	}

	//PFCHG_V1
	whatsAppFlowForCollectionClose = () => {
		this.userDataDtoForWhatsApp = new UserDataDto();
		this.listOfMemoNumber = [];
		for (let i = 0; i < this.cashmemoDtoAmtCollectedConfirmCheckedList.length; i++) {
			this.listOfMemoNumber.push(this.cashmemoDtoAmtCollectedConfirmCheckedList[i].memoNumber);
		}
		this.userDataDtoForWhatsApp.list = this.listOfMemoNumber;
		this.userDataDtoForWhatsApp.city = this.userDataDtoReturnSession.office;
		this.userDataDtoForWhatsApp.mainStation = this.userDataDtoReturnSession.mainStation;
		this.memoService.whatsAppNotificationFlowForDestCollectionClose(this.userDataDtoForWhatsApp).subscribe(
			(response) => {
				this.cashmemoDtoAmtCollectedConfirmCheckedList = [];
				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};

}
