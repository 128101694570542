<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showCollectionManRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Collection Man Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy"
                                                            name="searchBy" #searchBy
                                                            (change)="searchByMode(searchBy.value)">
                                                            <option selected value="gcTakenBy">G.C Taken By</option>
                                                            <option value="gcAmountCollected">GC Amount
                                                                Collected</option>
                                                            <option value="gcLeftToParty">GC Left to Party</option>
                                                            <option value="gcLeftToPartyStmtSummary">GC Left
                                                                to Party'Statement Summary'</option>
                                                            <option value="collectionConsolidatedReport">Collection
                                                                Consolidated Report</option>
                                                            <option value="leftToPartyPendingAcknowledgmentStmts">Left
                                                                To-Party Pending Acknowledgment Stmts</option>
                                                            <option value="cashmemoStock">Cashmemo Stock</option>
                                                            <option value="dailyCollection">Daily Collection</option>
                                                            <option value="gcAmountCollectedConfirm"
                                                                [hidden]="!showAmtCollectedConfirmOption">GC Amount
                                                                Collected Confirm</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewReportMode" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Report Mode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}reportMode"
                                                            name="reportMode" #reportMode
                                                            (change)="reportModeMode(reportMode.value)">
                                                            <option selected value="rptModeDateWise">Date
                                                                Wise</option>
                                                            <option *ngIf="showTillDate" value="rptModeTillDate">Till
                                                                Date</option>
                                                            <option *ngIf="showDaily" value="rptModeDaily">Daily
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewFromDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control"
                                                            id="{{pageId}}fromDate" [(ngModel)]="setTodayDateOnFromDate"
                                                            placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
                                                            #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewToDate" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate"
                                                            [(ngModel)]="setTodayDateOnToDate" class="form-control"
                                                            placeholder="yyyy-mm-dd" name="toDate" ngbDatepicker
                                                            #toDate="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDate.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <!-- 											the first autocomplete starts -->
                                            <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text"
                                                                class="form-control" [(ngModel)]="modelDestination"
                                                                [ngbTypeahead]="searchDestination"
                                                                (selectItem)="consigneeDestListener($event)"
                                                                [resultFormatter]="formatterDestination"
                                                                [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                                placeholder="Select Destination" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewPartyName" class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Party Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input #consigneeName id="{{pageId}}consigneeName"
                                                            name="consigneeName" type="text" class="form-control"
                                                            placeholder="Select Consignee Name"
                                                            [(ngModel)]="modelConsigneeName"
                                                            [ngbTypeahead]="consigneeNameSearchTA"
                                                            (selectItem)="rowSelectedConsignee($event)"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [resultFormatter]="formatterConsigneeName"
                                                            [inputFormatter]="formatterConsigneeName"
                                                            (focus)="focusConsigneeNameTA$.next($any($event).target.value)"
                                                            [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- 												<div  class="col-sm-12 col-md-12"> -->
                                            <!-- 												<div class="form-group"> -->
                                            <!-- 													<div class="input-group"> -->
                                            <!-- 														<label>Staion</label> -->
                                            <!-- 														<div class="input-group-prepend"> -->
                                            <!-- 															<span class="input-group-text"> <i -->
                                            <!-- 																class="fas fa-user"></i> -->
                                            <!-- 															</span> -->
                                            <!-- 														</div> -->
                                            <!-- 														<input #station id="{{pageId}}station" -->
                                            <!-- 															name="station" type="text" class="form-control" -->
                                            <!-- 															placeholder="Select Station" -->
                                            <!-- 															[(ngModel)]="modelStation" -->
                                            <!-- 															[ngbTypeahead]="stationSearchTA" -->
                                            <!-- 															[ngModelOptions]="{standalone: true}" -->
                                            <!-- 															[resultFormatter]="formatterStation" -->
                                            <!-- 															[inputFormatter]="formatterStation" -->
                                            <!-- 															(focus)="focusStationTA$.next($any($event).target.value)" -->
                                            <!-- 															[ngModelOptions]="{standalone: true}" /> -->
                                            <!-- 													</div> -->
                                            <!-- 												</div> -->
                                            <!-- 											</div> -->

                                            <!-- 											the first autocomplete ends -->
                                            <div *ngIf="viewCollectionMan" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Collection Man</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input #collectionMan id="{{pageId}}collectionMan"
                                                            name="collectionMan" type="text" class="form-control"
                                                            placeholder="Select Collection Man"
                                                            [(ngModel)]="modelCollectionMan"
                                                            [ngbTypeahead]="collectionManSearchTA"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [resultFormatter]="formatterCollectionMan"
                                                            [inputFormatter]="formatterCollectionMan"
                                                            (focus)="focusCollectionManTA$.next($any($event).target.value)"
                                                            [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div *ngIf="viewAreaName" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}">
                                                        <label>Area Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-map-marker-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input #areaName id="{{pageId}}areaName" name="areaName"
                                                            type="text" class="form-control" placeholder="Select Area"
                                                            [(ngModel)]="modelAreaName"
                                                            [ngbTypeahead]="areaNameSearchTA"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [resultFormatter]="formatterAreaName"
                                                            [inputFormatter]="formatterAreaName"
                                                            (focus)="focusAreaNameTA$.next($any($event).target.value)"
                                                            [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div *ngIf="viewCashmemoStockOptions" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search With</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchWith"
                                                            name="searchWith" #searchWith
                                                            (change)="searchWithMode(searchWith.value)">
                                                            <option selected value="summary">Summary</option>
                                                            <option value="detail">Detail</option>
                                                            <option value="partyWise">Party Wise</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="viewSendSms" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}sendSms">
                                                        <label>SMS SendTo(Multiple Number Separted By
                                                            Comma's)</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-envelope"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}smsNo"
                                                            name="smsNo" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!gcAmountCollectedDaysDiffView">
                                                <div [hidden]="!gcAmountCollectedTable" class="col-sm-12 col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}sendSms">
                                                            <label>Collection-SRD Stock Diff(Days)</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-envelope"></i>
                                                                </span>
                                                            </div>
                                                            <input type="number" class="form-control"
                                                                id="{{pageId}}diffDays" name="diffDays"
                                                                aria-describedby="basic-addon11">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
                                (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
                                (click)="clearMethod();">Clear</button>
                            <button *ngIf="viewSendSms" type="submit" class="btn btn-success m-r-10"
                                id="{{pageId}}searchBtn" (click)="confirmSendSMS();">Send SMS</button>
                        </div>
                    </div>

                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center'
                                            style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">

                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                                <h6 class="card-title" [hidden]="!dailyCollectionDetailsTable">Daily
                                                    Collection Details</h6>
                                                <h6 class="card-title" [hidden]="!gcTakenByTable">G.C. Taken By</h6>
                                                <h6 class="card-title" [hidden]="!gcLeftToPartyTable">G.C Left To Party
                                                </h6>
                                                <h6 class="card-title" [hidden]="!gcLeftToPartyStatementSummaryTable">
                                                    G.C Left To Party Statement Summary</h6>
                                                <h6 class="card-title" [hidden]="!gcAmountCollectedTable">G.C AMOUNT
                                                    COLLECTED</h6>
                                                <h6 class="card-title" [hidden]="!leftToPartyStatementTable">Left To
                                                    Party Statement</h6>
                                                <h6 class="card-title" [hidden]="!collectionConsolidatedTable">
                                                    Collection Consolidated Report</h6>
                                                <h6 class="card-title" [hidden]="!cashmemoStockSummaryTable">Cashmemo
                                                    Stock Summary</h6>
                                                <h6 class="card-title" [hidden]="!gcAmountCollectedConfirmTable">G.C
                                                    AMOUNT COLLECTED CONFIRM</h6>
                                            </div>


                                            <div class="col-md-4">

                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintGcAmtCollected()" id="{{pageId}}printAllBtn"
                                                    [hidden]="!gcAmountCollectedTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>
                                                <button type="submit" class="dt-button" style="margin-left: 43%;"
                                                    (click)="gcTakenPrintByGroupingConsignee()"
                                                    id="{{pageId}}gcTakenPrintByGroupingConsigneeBtn"
                                                    [hidden]="!gcTakenByTable">
                                                    <span><i class="fas fa-print">Partywise</i></span>
                                                </button>

                                                <button type="submit" class="dt-button" style="margin-left: 0%;"
                                                    (click)="customPrintGcTakenBy()" id="{{pageId}}printAllBtn"
                                                    [hidden]="!gcTakenByTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>

                                                <button type="submit" class="dt-button" style="margin-left: 16%;"
                                                    (click)="setGCLeftToPartyReportGroupingPrint()"
                                                    id="{{pageId}}printAllBtn" [hidden]="!gcLeftToPartyTable">
                                                    <span><i class="fas fa-print">Partywise</i></span>
                                                </button>
                                                <button type="submit" class="dt-button" style="margin-left: 1%;"
                                                    (click)="customPrintGcLeftToParty()" id="{{pageId}}printAllBtn"
                                                    [hidden]="!gcLeftToPartyTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>


                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintGcLeftToPartyStmtSum()"
                                                    id="{{pageId}}printAllBtn"
                                                    [hidden]="!gcLeftToPartyStatementSummaryTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>


                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintCollectionConRpt()" id="{{pageId}}printAllBtn"
                                                    [hidden]="!collectionConsolidatedTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>


                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintLeftToPartyPndAchStmt()"
                                                    id="{{pageId}}printAllBtn" [hidden]="!leftToPartyStatementTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>


                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintCashMemoStockSummary()"
                                                    id="{{pageId}}printAllBtn" [hidden]="!cashmemoStockSummaryTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>
                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintCashMemoStockDetails()"
                                                    id="{{pageId}}printAllBtn" [hidden]="!cashmemoStockDetailsTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>
                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintCashMemoStockPartyWise()"
                                                    id="{{pageId}}printAllBtn" [hidden]="!cashmemoStockPartyWiseTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>
                                                <button type="submit" class="dt-button" style="margin-left: 74%;"
                                                    (click)="customPrintDailyCollection()" id="{{pageId}}printAllBtn"
                                                    [hidden]="!dailyCollectionDetailsTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>

                                                <button type="submit" class="dt-button" style="margin-left: 1%;"
                                                    (click)="setPringForGCLeftToPartyReport();"
                                                    id="{{pageId}}printLeftPartyBtn" [hidden]="!gcLeftToPartyTable">
                                                    <span><i class="fas fa-print">Print</i></span>
                                                </button>
                                                <div [hidden]="!gcAmountCollectedConfirmTable">
                                                    <button type="submit" class="dt-button" style="margin-left: 48%;"
                                                        (click)="amtCollectedConfirm();"
                                                        id="{{pageId}}amtCollectedConfirmBtn"
                                                        [hidden]="!showAmtCollectedConfirmOptionBtn">
                                                        <span><i class="fas fa-edit">Confirm
                                                                Collection</i></span></button>
                                                    <button type="submit" class="dt-button" style="margin-left: 48%;"
                                                        (click)="checkuncheckallCollConfirm();"
                                                        id="{{pageId}}CollectionConfirmAllBtn"
                                                        [hidden]="!showAmtCollectedConfirmOptionBtn">
                                                        <span><i class="fas fa-edit">Select/Deselect
                                                                All</i></span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body" [hidden]="!dailyCollectionDetailsTable">
                                                    <table datatable id="{{pageId}}dailyCollectionDetailsTableId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsDailyCollectionDetails"
                                                        [dtTrigger]="dtTriggerDailyCollectionDetails">

                                                        <thead>
                                                            <tr>
                                                                <th [hidden]="!showPaymentFollowupReprint">Action</th>
                                                                <th>Collection No</th>
                                                                <th>Collection Man</th>
                                                                <th>No Of G.C.</th>
                                                                <th>Total Amount</th>
                                                                <th>Less</th>
                                                                <th>TDS Less</th>
                                                                <th>Claim Less</th>
                                                                <th>Amount Collected</th>
                                                                <th>Cash Amount</th>
                                                                <th>Cheque Amount</th>
                                                                <th>NEFT/IMPS Amount</th>
                                                                <th>Debit Ac Amount</th>
                                                                <th>UPI</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let dailyCollectionDetailsData of dailyCollectionDetailsDataList ">
                                                                <td [hidden]="!showPaymentFollowupReprint">
                                                                    <button
                                                                        style="padding: 1px 0px; background-color: #ffffff00;"
                                                                        class="btn m-r-10"
                                                                        id="{{pageId}}voucherPrintBtn"
                                                                        (click)="customPrintPaymentChallan(dailyCollectionDetailsData);">
                                                                        <i title="Print Payment Follwoup"
                                                                            class="fas fa-print"></i>
                                                                    </button>
                                                                </td>
                                                                <td>{{"Collection "+dailyCollectionDetailsData.size}}
                                                                </td>
                                                                <td>{{ dailyCollectionDetailsData.collectionMan }}</td>
                                                                <td>{{ dailyCollectionDetailsData.totalMemos }}</td>
                                                                <td>{{ dailyCollectionDetailsData.grandTotal }}</td>
                                                                <td>{{ dailyCollectionDetailsData.less}}</td>
                                                                <td>{{ dailyCollectionDetailsData.tds }}</td>
                                                                <td>{{ dailyCollectionDetailsData.claim }}</td>
                                                                <td>{{ dailyCollectionDetailsData.pdcAmt ==null ? 0.0 :
                                                                    dailyCollectionDetailsData.pdcAmt}}</td>
                                                                <td>{{ dailyCollectionDetailsData.cashAmt ==null ? 0.0 :
                                                                    dailyCollectionDetailsData.cashAmt }}</td>
                                                                <td>{{ dailyCollectionDetailsData.chequeAmt ==null ? 0.0
                                                                    : dailyCollectionDetailsData.chequeAmt}}</td>
                                                                <td>{{ dailyCollectionDetailsData.neftAmt ==null ? 0.0 :
                                                                    dailyCollectionDetailsData.neftAmt}}</td>
                                                                <td>{{ dailyCollectionDetailsData.debitAcAmt ==null ?
                                                                    0.0 : dailyCollectionDetailsData.debitAcAmt}}</td>
                                                                <td>{{ dailyCollectionDetailsData.upiAmt ==null ? 0.0 :
                                                                    dailyCollectionDetailsData.upiAmt}}</td>

                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td [hidden]="!showPaymentFollowupReprint"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>


                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!gcTakenByTable">
                                                    <table datatable id="{{pageId}}gcTakenById"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsGcTakenBy"
                                                        [dtTrigger]="dtTriggerGcTakenBy">

                                                        <thead>
                                                            <tr>
                                                                <th>Party Name</th>
                                                                <th>Memo No</th>
                                                                <th>Memo Dt(A)</th>
                                                                <th>Assign Dt(B)</th>
                                                                <th>Diff(B-A)</th>
                                                                <th>Memo Amt</th>
                                                                <th>LR No</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let gcTakenByData of gcTakenByDataList ">
                                                                <td>{{ gcTakenByData.consigneeName }}</td>
                                                                <td>{{ gcTakenByData.memoNumber }}</td>
                                                                <td>{{ gcTakenByData.memoDateStr }}</td>
                                                                <td>{{ gcTakenByData.assignedDateStr }}</td>
                                                                <td>{{ gcTakenByData.days }}</td>
                                                                <td>{{ gcTakenByData.amount }}</td>
                                                                <td>{{ gcTakenByData.lrNumber }}</td>

                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!gcLeftToPartyTable">
                                                    <table datatable id="{{pageId}}gcLeftToPartyId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsGcLeftToParty"
                                                        [dtTrigger]="dtTriggerGcLeftToParty">

                                                        <thead>
                                                            <tr>
                                                                <th>Memo No</th>
                                                                <th>Memo Dt(A)</th>
                                                                <th>Assigned Dt(B)</th>
                                                                <th>Diff(CurDt-B)</th>
                                                                <th>Memo Amt</th>
                                                                <th>LR No</th>
                                                                <th>Consignee Name</th>
                                                                <th>To Party Stm. No.</th>
                                                                <th>Statement Copy</th>
                                                                <th>Statement Copy 2</th>
                                                                <th>Statement Copy 3</th>
                                                                <th>Left To Party</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let gcLeftToPartyData of gcLeftToPartyDataList ">
                                                                <td>{{ gcLeftToPartyData.memoNumber }}</td>
                                                                <td>{{ gcLeftToPartyData.memoDateStr }}</td>
                                                                <td>{{ gcLeftToPartyData.assignedDateStr }}</td>
                                                                <td>{{ gcLeftToPartyData.days }}</td>
                                                                <td>{{ gcLeftToPartyData.amount }}</td>
                                                                <td>{{ gcLeftToPartyData.lrNumber }}</td>
                                                                <td>{{ gcLeftToPartyData.consigneeName }}</td>
                                                                <td>{{ gcLeftToPartyData.statementNo }}</td>
                                                                <td>
                                                                    <a (click)="getImgForCpy1(gcLeftToPartyData);"
                                                                        class="pointer"> <u>{{
                                                                            gcLeftToPartyData.imgURLStmCpy == null ?
                                                                            "NA" :
                                                                            gcLeftToPartyData.imgURLStmCpy == "NA" ?
                                                                            "NA" : "View
                                                                            Statement Document" }}</u></a>
                                                                </td>
                                                                <td>
                                                                    <a (click)="getImgForCpy2(gcLeftToPartyData);"
                                                                        class="pointer"> <u>{{
                                                                            gcLeftToPartyData.imgURLStmCpy2 == null ?
                                                                            "NA" :
                                                                            gcLeftToPartyData.imgURLStmCpy2 == "NA" ?
                                                                            "NA" :
                                                                            "View Statement Document" }}</u></a>
                                                                </td>
                                                                <td>
                                                                    <a (click)="getImgForCpy3(gcLeftToPartyData);"
                                                                        class="pointer"> <u>{{
                                                                            gcLeftToPartyData.imgURLStmCpy3 == null ?
                                                                            "NA" :
                                                                            gcLeftToPartyData.imgURLStmCpy3 == "NA" ?
                                                                            "NA" :
                                                                            "View Statement Document" }}</u></a>
                                                                </td>

                                                                <td>{{ gcLeftToPartyData.consignee }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!gcLeftToPartyStatementSummaryTable">
                                                    <table datatable id="{{pageId}}gcLeftToPartyStatementSummaryId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsGcLeftToPartyStatementSummary"
                                                        [dtTrigger]="dtTriggerGcLeftToPartyStatementSummary">

                                                        <thead>
                                                            <tr>
                                                                <th>Statement No</th>
                                                                <th>Stmt Dt(A)</th>
                                                                <th>Diff(CurDt-A)</th>
                                                                <th>Tot Memo No</th>
                                                                <th>Tot Memo Amount</th>
                                                                <th>Collection Man</th>
                                                                <th>Consignee Name</th>
                                                                <th>Statement Copy</th>
                                                                <th>Left To Party</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let gcLeftToPartyStatementSummaryData of gcLeftToPartyStatementSummaryDataList ">
                                                                <td
                                                                    (click)="gotoLeftToPartyPopUPLinkData(gcLeftToPartyStatementSummaryData,printPreviewStmtPopUpPopUpTemplate);">
                                                                    <u class="hyperLink_td">{{
                                                                        gcLeftToPartyStatementSummaryData.statementNo
                                                                        }}</u>
                                                                </td>
                                                                <td>{{ gcLeftToPartyStatementSummaryData.stmtDateStr }}
                                                                </td>
                                                                <td>{{ gcLeftToPartyStatementSummaryData.daysDiff }}
                                                                </td>
                                                                <td>{{ gcLeftToPartyStatementSummaryData.totalMemos }}
                                                                </td>
                                                                <td>{{ gcLeftToPartyStatementSummaryData.grandTotal }}
                                                                </td>
                                                                <td>{{ gcLeftToPartyStatementSummaryData.collectionMan}}
                                                                </td>
                                                                <td>{{ gcLeftToPartyStatementSummaryData.consigneeName
                                                                    }}</td>

                                                                <td>
                                                                    <a (click)="getImgForLeftToPartStmtSumm(gcLeftToPartyStatementSummaryData);"
                                                                        class="pointer"> <u>{{
                                                                            gcLeftToPartyStatementSummaryData.imgURLStmCpy
                                                                            ==
                                                                            null ? "NA" :
                                                                            gcLeftToPartyStatementSummaryData.imgURLStmCpy
                                                                            ==
                                                                            "NA" ? "NA" : "View Statement Document"
                                                                            }}</u></a>
                                                                </td>
                                                                <td>{{ gcLeftToPartyStatementSummaryData.consignee }}
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>

                                                <div class="box-body" [hidden]="!gcAmountCollectedTable">
                                                    <table datatable id="{{pageId}}gcAmountCollectedId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsGcAmountCollected"
                                                        [dtTrigger]="dtTriggerGcAmountCollected">
                                                        <thead>
                                                            <tr>
                                                                <th>Memo No</th>
                                                                <th>Memo Dt(A)</th>
                                                                <th>Assigned Dt(B)</th>
                                                                <th>Collection Dt(C)</th>
                                                                <th>Diff(C-A)</th>
                                                                <th>Memo Amt</th>
                                                                <th>Less</th>
                                                                <th>TDS</th>
                                                                <th>Claim</th>
                                                                <th>Chq Amt</th>
                                                                <th>NEFT Amt</th>
                                                                <th>Cash</th>
                                                                <th>Debit A/c</th>
                                                                <th>UPI Amt</th>
                                                                <th>Card Amt</th>
                                                                <th>Amt Collected</th>
                                                                <th>Ref No</th>
                                                                <th>LR No</th>
                                                                <th>Party Name</th>
                                                                <th>Collection No</th>
                                                                <th>Collection Man</th>
                                                                <th>Art</th>
                                                                <th style="display: none;">Remark</th>
                                                                <th style="display: none;">Area</th>
                                                                <th>Chq Date</th>
                                                                <th>Invoice Date</th>
                                                                <th>To Pay</th>
                                                                <th [hidden]="!gcAmountCollectedDaysDiffView">SRD Stock
                                                                    Dt(D)</th>
                                                                <th [hidden]="!gcAmountCollectedDaysDiffView">Diff
                                                                    Dt(C-D)</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let gcAmountCollectedData of gcAmountCollectedDataList ">
                                                                <td>{{ gcAmountCollectedData.memoNumber }}</td>
                                                                <td>{{ gcAmountCollectedData.memoDateStr }}</td>
                                                                <td>{{ gcAmountCollectedData.assignedDateStr }}</td>
                                                                <td>{{ gcAmountCollectedData.completedOnStr }}</td>
                                                                <td>{{ gcAmountCollectedData.days }}</td>
                                                                <td>{{ gcAmountCollectedData.amount }}</td>
                                                                <td>{{ gcAmountCollectedData.less ==null ? 0.0 :
                                                                    gcAmountCollectedData.less}}
                                                                </td>
                                                                <td>{{ gcAmountCollectedData.tds ==null ? 0.0 :
                                                                    gcAmountCollectedData.tds }}</td>
                                                                <td>{{ gcAmountCollectedData.claim ==null ? 0.0 :
                                                                    gcAmountCollectedData.claim }}</td>
                                                                <td>{{ gcAmountCollectedData.chequeAmt ==null ? 0.0 :
                                                                    gcAmountCollectedData.chequeAmt }}</td>
                                                                <td>{{ gcAmountCollectedData.neftAmt ==null ? 0.0 :
                                                                    gcAmountCollectedData.neftAmt }}</td>
                                                                <td>{{ gcAmountCollectedData.cashAmt ==null ? 0.0 :
                                                                    gcAmountCollectedData.cashAmt}}
                                                                </td>

                                                                <td>{{ gcAmountCollectedData.debitAcAmt ==null ? 0.0 :
                                                                    gcAmountCollectedData.debitAcAmt }}</td>
                                                                <td>{{ gcAmountCollectedData.upiAmt ==null ? 0.0 :
                                                                    gcAmountCollectedData.upiAmt}}
                                                                </td>
                                                                <td>{{ gcAmountCollectedData.cardAmt ==null ? 0.0 :
                                                                    gcAmountCollectedData.cardAmt}}
                                                                </td>

                                                                <td>{{ gcAmountCollectedData.amountCollected }}</td>
                                                                <td>{{ gcAmountCollectedData.chqNumber }}</td>
                                                                <td>{{ gcAmountCollectedData.lrNumber }}</td>
                                                                <td>{{ gcAmountCollectedData.consigneeName }}</td>
                                                                <td>{{ "Collection "+gcAmountCollectedData.size }}</td>
                                                                <td>{{ gcAmountCollectedData.collectionMan }}</td>
                                                                <td>{{ gcAmountCollectedData.article }}</td>
                                                                <td style="display: none;">{{
                                                                    gcAmountCollectedData.remarks }}</td>
                                                                <td style="display: none;">{{ gcAmountCollectedData.area
                                                                    }}</td>
                                                                <td>{{ gcAmountCollectedData.date }}</td>
                                                                <td>{{ gcAmountCollectedData.sameDate }}</td>
                                                                <td>{{ gcAmountCollectedData.topay }}</td>
                                                                <td [hidden]="!gcAmountCollectedDaysDiffView">{{
                                                                    gcAmountCollectedData.srdCollectionStockDateStr }}
                                                                </td>
                                                                <td [hidden]="!gcAmountCollectedDaysDiffView">{{
                                                                    gcAmountCollectedData.daysDiff }}</td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style="display: none;"></td>
                                                                <td style="display: none;"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]="!gcAmountCollectedDaysDiffView"></td>
                                                                <td [hidden]="!gcAmountCollectedDaysDiffView"></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>

                                                <div [hidden]="!gcAmountCollectedDaysDiffView">
                                                    <div class="box-body" [hidden]="!gcAmountCollectedTable">
                                                        <table datatable id="{{pageId}}gcAmountCollectedIdDaysDiff"
                                                            class="table table-striped table-bordered row-border hover"
                                                            [dtOptions]="dtOptionsGcAmountCollectedDaysDiff"
                                                            [dtTrigger]="dtTriggerGcAmountCollectedDaysDiff">
                                                            <thead>
                                                                <tr>
                                                                    <th>Memo No</th>
                                                                    <th>Memo Dt(A)</th>
                                                                    <th>Assigned Dt(B)</th>
                                                                    <th>Collection Dt(C)</th>
                                                                    <th>Diff(C-A)</th>
                                                                    <th>Memo Amt</th>
                                                                    <th>Less</th>
                                                                    <th>TDS</th>
                                                                    <th>Claim</th>
                                                                    <th>Chq Amt</th>
                                                                    <th>NEFT Amt</th>
                                                                    <th>Cash</th>
                                                                    <th>Debit Amt</th>
                                                                    <th>UPI Amt</th>
                                                                    <th>Amt Collected</th>
                                                                    <th>Ref No</th>
                                                                    <th>LR No</th>
                                                                    <th>Party Name</th>
                                                                    <th>Collection No</th>
                                                                    <th>Collection Man</th>
                                                                    <th>Art</th>
                                                                    <th style="display: none;">Remark</th>
                                                                    <th style="display: none;">Area</th>
                                                                    <th>Chq Date</th>
                                                                    <th>Invoice Date</th>
                                                                    <th>To Pay</th>
                                                                    <th>SRD Stock Dt(D)</th>
                                                                    <th>Diff Dt(C-D)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let gcAmountCollectedData of gcAmountCollectedDataListDaysDiff ">
                                                                    <td>{{ gcAmountCollectedData.memoNumber }}</td>
                                                                    <td>{{ gcAmountCollectedData.memoDateStr }}</td>
                                                                    <td>{{ gcAmountCollectedData.assignedDateStr }}</td>
                                                                    <td>{{ gcAmountCollectedData.completedOnStr }}</td>
                                                                    <td>{{ gcAmountCollectedData.days }}</td>
                                                                    <td>{{ gcAmountCollectedData.amount }}</td>
                                                                    <td>{{ gcAmountCollectedData.less ==null ? 0.0 :
                                                                        gcAmountCollectedData.less}}
                                                                    </td>
                                                                    <td>{{ gcAmountCollectedData.tds ==null ? 0.0 :
                                                                        gcAmountCollectedData.tds }}</td>
                                                                    <td>{{ gcAmountCollectedData.claim ==null ? 0.0 :
                                                                        gcAmountCollectedData.claim }}</td>
                                                                    <td>{{ gcAmountCollectedData.chequeAmt ==null ? 0.0
                                                                        : gcAmountCollectedData.chequeAmt }}</td>
                                                                    <td>{{ gcAmountCollectedData.neftAmt ==null ? 0.0 :
                                                                        gcAmountCollectedData.neftAmt }}</td>
                                                                    <td>{{ gcAmountCollectedData.cashAmt ==null ? 0.0 :
                                                                        gcAmountCollectedData.cashAmt}}
                                                                    </td>
                                                                    <td>{{ gcAmountCollectedData.debitAcAmt ==null ? 0.0
                                                                        : gcAmountCollectedData.debitAcAmt }}</td>
                                                                    <td>{{ gcAmountCollectedData.upiAmt ==null ? 0.0 :
                                                                        gcAmountCollectedData.upiAmt}}
                                                                    </td>
                                                                    <td>{{ gcAmountCollectedData.amountCollected }}</td>
                                                                    <td>{{ gcAmountCollectedData.chqNumber }}</td>
                                                                    <td>{{ gcAmountCollectedData.lrNumber }}</td>
                                                                    <td>{{ gcAmountCollectedData.consigneeName }}</td>
                                                                    <td>{{ "Collection "+gcAmountCollectedData.size }}
                                                                    </td>
                                                                    <td>{{ gcAmountCollectedData.collectionMan }}</td>
                                                                    <td>{{ gcAmountCollectedData.article }}</td>
                                                                    <td style="display: none;">{{
                                                                        gcAmountCollectedData.remarks }}</td>
                                                                    <td style="display: none;">{{
                                                                        gcAmountCollectedData.area }}</td>
                                                                    <td>{{ gcAmountCollectedData.date }}</td>
                                                                    <td>{{ gcAmountCollectedData.sameDate }}</td>
                                                                    <td>{{ gcAmountCollectedData.topay }}</td>
                                                                    <td>{{
                                                                        gcAmountCollectedData.srdCollectionStockDateStr
                                                                        }}</td>
                                                                    <td>{{ gcAmountCollectedData.daysDiff }}</td>
                                                                </tr>

                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td style="display: none;"></td>
                                                                    <td style="display: none;"></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="box-body" [hidden]="!leftToPartyStatementTable">
                                                    <table datatable id="{{pageId}}leftToPartyStatementId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsLeftToPartyStatement"
                                                        [dtTrigger]="dtTriggerLeftToPartyStatement">

                                                        <thead>
                                                            <tr>
                                                                <th>Statement No</th>
                                                                <th>Statement Date</th>
                                                                <th>Pending Memos</th>
                                                                <th>Pending Memo Amount</th>
                                                                <th>Left To Party</th>
                                                                <th>Collection Man</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let leftToPartyStatementData of leftToPartyStatementDataList ">
                                                                <td
                                                                    (click)="gotoLeftToPartyPopUPLinkData(leftToPartyStatementData,printPreviewStmtPopUpPopUpTemplate);">
                                                                    <u class="hyperLink_td">{{
                                                                        leftToPartyStatementData.statementNo }}</u>
                                                                </td>
                                                                <td>{{ leftToPartyStatementData.stmtDateStr }}</td>
                                                                <td>{{ leftToPartyStatementData.totalMemos }}</td>
                                                                <td>{{ leftToPartyStatementData.grandTotal}}</td>
                                                                <td>{{ leftToPartyStatementData.consigneeName }}</td>
                                                                <td>{{ leftToPartyStatementData.collectionMan }}</td>
                                                                <td><button type="submit" class="btn btn-primary m-r-10"
                                                                        style="padding: 1px 4px;"
                                                                        id="{{pageId}}getRetrunLrBtn"
                                                                        (click)="setAckRec(leftToPartyStatementData,leftToPartyStmtPopUpTemplate);">
                                                                        <i class="fas fa-file-alt"></i> Ack Rec
                                                                    </button></td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!collectionConsolidatedTable">
                                                    <table datatable id="{{pageId}}collectionConsolidatedId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsCollectionConsolidatedReport"
                                                        [dtTrigger]="dtTriggerCollectionConsolidatedReport">

                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>G.C. Out</th>
                                                                <th>G.C. Amount</th>
                                                                <th>G.C. In</th>
                                                                <th>G.C. Amount</th>
                                                                <th>G.C. Bal</th>
                                                                <th>G.C. Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let collectionConsolidatedReportData of collectionConsolidatedReportDataList ">
                                                                <td>{{ collectionConsolidatedReportData.collectionMan }}
                                                                </td>
                                                                <td>{{ collectionConsolidatedReportData.capacity ==null
                                                                    ? 0 :collectionConsolidatedReportData.capacity}}
                                                                </td>
                                                                <td>{{ collectionConsolidatedReportData.amount ==null ?
                                                                    0.0 :collectionConsolidatedReportData.amount}}</td>
                                                                <td>{{ collectionConsolidatedReportData.memoBack ==null
                                                                    ? 0 :collectionConsolidatedReportData.memoBack }}
                                                                </td>
                                                                <td>{{ collectionConsolidatedReportData.memoBackAmt
                                                                    ==null ? 0.0
                                                                    :collectionConsolidatedReportData.memoBackAmt }}
                                                                </td>
                                                                <td>{{ collectionConsolidatedReportData.capacityGCIn
                                                                    ==null ?0
                                                                    :collectionConsolidatedReportData.capacityGCIn }}
                                                                </td>
                                                                <td>{{ collectionConsolidatedReportData.amountGCIn
                                                                    ==null ?0.0
                                                                    :collectionConsolidatedReportData.amountGCIn }}</td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!cashmemoStockSummaryTable">
                                                    <table datatable id="{{pageId}}cashmmemostockSummaryId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsCashmemoStockSummary"
                                                        [dtTrigger]="dtTriggerCashmemoStockSummary">

                                                        <thead>
                                                            <tr>
                                                                <th>Collection Man</th>
                                                                <th>Tot.Memo</th>
                                                                <th>Tot.Amt</th>
                                                                <th>Tot.Art</th>
                                                                <th>Area</th>
                                                                <th>Tot.Stock Lrs</th>
                                                                <th>Tot.Stock Amt</th>
                                                                <th>Tot.Assigned Lrs</th>
                                                                <th>Tot.Assigned Amt</th>
                                                                <th>Tot.To-Party Lrs</th>
                                                                <th>Tot.To-Party Amt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let cashmemoStockSummaryData of cashmemoStockSummaryDataList ">
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.collectionMan }}</td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.totalMemos }}</td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.grandTotal }}</td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.totalArticles }}</td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.area }}</td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.pendingMemos ==null ? 0
                                                                    :cashmemoStockSummaryData.pendingMemos}}
                                                                </td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.pendingMemoTotal==null ?
                                                                    0.0 :cashmemoStockSummaryData.pendingMemoTotal }}
                                                                </td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.assignedMemos==null ?
                                                                    0:cashmemoStockSummaryData.assignedMemos }}</td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.assignedMemoTotal==null
                                                                    ? 0.0 : cashmemoStockSummaryData.assignedMemoTotal}}
                                                                </td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.toPartyMemos ==null ? 0
                                                                    :cashmemoStockSummaryData.toPartyMemos}}
                                                                </td>
                                                                <td
                                                                    *ngIf="cashmemoStockSummaryData.collectionMan !='SRD COLLECTION STOCK'">
                                                                    {{ cashmemoStockSummaryData.toPartyMemoTotal==null ?
                                                                    0.0 : cashmemoStockSummaryData.toPartyMemoTotal}}
                                                                </td>



                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.collectionMan }}</td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.totalMemos ==null ? 0
                                                                    :cashmemoStockSummaryData.totalMemos}}
                                                                </td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.grandTotal ==null ? 0.0
                                                                    : cashmemoStockSummaryData.grandTotal }}</td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.totalArticles ==null ? 0
                                                                    : cashmemoStockSummaryData.totalArticles}}
                                                                </td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.area }}</td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.pendingMemos ==null ? 0
                                                                    :cashmemoStockSummaryData.pendingMemos}}
                                                                </td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.pendingMemoTotal==null ?
                                                                    0.0 :cashmemoStockSummaryData.pendingMemoTotal }}
                                                                </td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.assignedMemos==null ?
                                                                    0:cashmemoStockSummaryData.assignedMemos }}</td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.assignedMemoTotal==null
                                                                    ? 0.0 : cashmemoStockSummaryData.assignedMemoTotal}}
                                                                </td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.toPartyMemos ==null ? 0
                                                                    :cashmemoStockSummaryData.toPartyMemos}}
                                                                </td>
                                                                <td *ngIf="cashmemoStockSummaryData.collectionMan=='SRD COLLECTION STOCK'"
                                                                    style='font-size: 13px; color: green; font-weight: bold;'>
                                                                    {{ cashmemoStockSummaryData.toPartyMemoTotal==null ?
                                                                    0.0 : cashmemoStockSummaryData.toPartyMemoTotal}}
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>

                                                <div class="box-body" [hidden]="!cashmemoStockDetailsTable">
                                                    <table datatable id="{{pageId}}cashmemoStockDetailsId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsCashmemoStockDetails"
                                                        [dtTrigger]="dtTriggerCashmemoStockDetails">

                                                        <thead>
                                                            <tr>
                                                                <th>Consignee Name</th>
                                                                <th>Memo Number</th>
                                                                <th>LR No</th>
                                                                <th>Amt</th>
                                                                <th>Bkg Dt</th>
                                                                <th>Memo Dt</th>
                                                                <th>Art</th>
                                                                <th>Area</th>
                                                                <th>Collection Man</th>
                                                                <th>Status</th>
                                                                <th>Cur Dt-Memo Dt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let cashmemoStockDetailsData of cashmemoStockDetailsDataList ">
                                                                <td>{{ cashmemoStockDetailsData.consigneeName }}</td>
                                                                <td>{{ cashmemoStockDetailsData.memoNumber }}</td>
                                                                <td>{{ cashmemoStockDetailsData.lrNumber }}</td>
                                                                <td>{{ cashmemoStockDetailsData.amount }}</td>
                                                                <td>{{ cashmemoStockDetailsData.bookingDateStr }}</td>
                                                                <td>{{ cashmemoStockDetailsData.memoDateStr }}</td>
                                                                <td>{{ cashmemoStockDetailsData.article }}</td>
                                                                <td>{{ cashmemoStockDetailsData.area }}</td>
                                                                <td>{{ cashmemoStockDetailsData.collectionMan }}</td>
                                                                <td>{{ cashmemoStockDetailsData.status }}</td>
                                                                <td>{{ cashmemoStockDetailsData.days }}</td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>

                                                <div class="box-body" [hidden]="!cashmemoStockPartyWiseTable">
                                                    <table datatable id="{{pageId}}cashmemoStockPartyWiseId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsCashmemoStockPartyWise"
                                                        [dtTrigger]="dtTriggerCashmemoStockPartyWise">

                                                        <thead>
                                                            <tr>
                                                                <th>Party</th>
                                                                <th>Total Memo</th>
                                                                <th>Total Amount</th>
                                                                <th>Articles</th>
                                                                <th>Area</th>
                                                                <th>Collection Man</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let cashmemoStockPartyWiseData of cashmemoStockPartyWiseDataList ">
                                                                <td>{{ cashmemoStockPartyWiseData.consigneeName }}</td>
                                                                <td>{{ cashmemoStockPartyWiseData.totalMemos }}</td>
                                                                <td>{{ cashmemoStockPartyWiseData.grandTotal }}</td>
                                                                <td>{{ cashmemoStockPartyWiseData.totalArticles }}</td>
                                                                <td>{{ cashmemoStockPartyWiseData.area }}</td>
                                                                <td>{{ cashmemoStockPartyWiseData.collectionMan }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>

                                                <div class="box-body" [hidden]="!gcAmountCollectedConfirmTable">
                                                    <table datatable id="{{pageId}}gcAmountCollectedConfirmId"
                                                        class="table table-striped table-bordered row-border hover"
                                                        [dtOptions]="dtOptionsGcAmountCollectedConfirm"
                                                        [dtTrigger]="dtTriggerGcAmountCollectedConfirm">

                                                        <thead>
                                                            <tr>
                                                                <th [hidden]=true>Grouped Col</th>
                                                                <th>Action</th>
                                                                <th>Memo No</th>
                                                                <th>Memo Dt(A)</th>
                                                                <th>Assigned Dt(B)</th>
                                                                <th>Collection Dt(C)</th>
                                                                <th>Diff(C-A)</th>
                                                                <th>Memo Amt</th>
                                                                <th>Less</th>
                                                                <th>TDS</th>
                                                                <th>Claim</th>
                                                                <th>Chq Amt</th>
                                                                <th>NEFT Amt</th>
                                                                <th>Cash</th>
                                                                <th>Debit Amt</th>
                                                                <th>UPI Amt</th>
                                                                <th>Amt Collected</th>
                                                                <th>Ref No</th>
                                                                <th>LR No</th>
                                                                <th>Party Name</th>
                                                                <th>Collection No</th>
                                                                <th>Collection Man</th>
                                                                <th>Art</th>

                                                                <th style="display: none;">Remark</th>
                                                                <th style="display: none;">Area</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let gcAmountCollectedConfirmData of gcAmountCollectedConfirmDataList ">
                                                                <td [hidden]=true>{{
                                                                    gcAmountCollectedConfirmData.groupedCollCol }}</td>
                                                                <td><input type="checkbox" [checked]="colmrIsChecked"
                                                                        name="{{ some }}" id='selectedLr'
                                                                        (change)="rowCheckBoxClicked($event, gcAmountCollectedConfirmData)" />
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.memoNumber }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.memoDateStr }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.assignedDateStr }}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.completedOnStr }}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.days }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.amount }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.less ==null ? 0.0 :
                                                                    gcAmountCollectedConfirmData.less}}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.tds ==null ? 0.0 :
                                                                    gcAmountCollectedConfirmData.tds }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.claim ==null ? 0.0 :
                                                                    gcAmountCollectedConfirmData.claim }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.chequeAmt ==null ?
                                                                    0.0 : gcAmountCollectedConfirmData.chequeAmt }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.neftAmt ==null ? 0.0
                                                                    : gcAmountCollectedConfirmData.neftAmt }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.cashAmt ==null ? 0.0
                                                                    : gcAmountCollectedConfirmData.cashAmt}}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.debitAcAmt ==null ?
                                                                    0.0 : gcAmountCollectedConfirmData.debitAcAmt }}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.upiAmt ==null ? 0.0
                                                                    : gcAmountCollectedConfirmData.upiAmt}}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.amountCollected }}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.chqNumber }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.lrNumber }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.consigneeName }}
                                                                </td>
                                                                <td>{{ "Collection "+gcAmountCollectedConfirmData.size
                                                                    }}</td>
                                                                <td>{{ gcAmountCollectedConfirmData.collectionMan }}
                                                                </td>
                                                                <td>{{ gcAmountCollectedConfirmData.article }}</td>

                                                                <td style="display: none;">{{
                                                                    gcAmountCollectedConfirmData.remarks }}</td>
                                                                <td style="display: none;">{{
                                                                    gcAmountCollectedConfirmData.area }}</td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td [hidden]=true></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td style="display: none;"></td>
                                                                <td style="display: none;"></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>



                </div>
                <!-- Row -->
            </div>
        </div>


        <div class="col-md-12">
            <div class="form-group">
                <div class="input-group" id="{{pageId}}">
                    <ng-template #leftToPartyStmtPopUpTemplate let-ok="close" let-d="dismiss">
                        <div class="modal-header">
                            <h5>Upload the Statement Copy</h5>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card-body">
                                        <div class="row">
                                            <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center'
                                                            style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Uploading Documnents....</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Statement Copy:</label> <input type="file"
                                                            id="{{pageId}}leftToPartyStmtFile1Upload"
                                                            (change)="onFileChangedLeftToPartyStmt1($event)"
                                                            #fileInputLeftToPartyStmt1 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Statement Copy:</label> <input type="file"
                                                            id="{{pageId}}leftToPartyStmtFile2Upload"
                                                            (change)="onFileChangedLeftToPartyStmt2($event)"
                                                            #fileInputLeftToPartyStmt2 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Statement Copy:</label> <input type="file"
                                                            id="{{pageId}}leftToPartyStmtFile3Upload"
                                                            (change)="onFileChangedLeftToPartyStmt3($event)"
                                                            #fileInputLeftToPartyStmt3 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-3">
                                                <button style="float: right;" type="button"
                                                    class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId"
                                                    (click)="uploadLeftToPartyStmtBtnPopUp()">Upload</button>
                                            </div>

                                        </div>
                                        <br>
                                        <div class="row">


                                            <div class="col-sm-12 col-md-12">
                                                <button style="float: right;" type="button" class="btn btn-dark"
                                                    id="{{pageId}}cancelBtnId"
                                                    (click)="closeLeftToPartyStmtBtnPopUp()">Cancel</button>
                                                <button style="float: right;" type="button"
                                                    class="btn btn-success m-r-10" id="{{pageId}}applyBtnId"
                                                    (click)="applyLeftToPartyStmtBtnPopUp()">Apply</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12" id="{{pageId}}popupModalForLeftToParty">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #printPreviewStmtPopUpPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewPrintPopupLeftToParty" id="{{pageId}}popupPrintPreviewId">
                                                <app-left-to-party-statement-details>
                                                </app-left-to-party-statement-details>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-success"
                                                id="{{pageId}}cancelBtnId" (click)="printPopUpBtn();">Print</button>
                                            <button style="float: right;" type="button" class="btn btn-dark"
                                                id="{{pageId}}cancelBtnId" (click)="closePopUpBtn();">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="viewGCTakenPrint" onafterprint="afterPrint()" id="{{pageId}}printGCTakenMemo">
        <app-payment-followup-assign-print></app-payment-followup-assign-print>
    </div>
    <div *ngIf="viewGCLeftToPartyPrint" onafterprint="afterPrint()" id="{{pageId}}printGCLeftToPartyMemo">
        <app-collectionman-gc-left-toparty-print></app-collectionman-gc-left-toparty-print>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

    <div *ngIf="viewLeftToPartyCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewLeftToPartyCustomPrintV1">
        <app-left-toparty-details-print></app-left-toparty-details-print>
    </div>

    <div *ngIf="viewGcTakenGroupingByConsigneeRptPrint" onafterprint="afterPrint()"
        id="{{pageId}}viewGcTakenGroupingByConsigneeRptPrint">
        <app-gc-taken-grouping-rpt-print></app-gc-taken-grouping-rpt-print>
        >
    </div>
    <div *ngIf="viewGcTakenLeftToPartyGroupingByConsigneeRptPrint" onafterprint="afterPrint()"
        id="{{pageId}}viewGcTakenLeftToPartyGroupingByConsigneeRptPrint">
        <app-gc-taken-left-to-party-grouping-rpt-print></app-gc-taken-left-to-party-grouping-rpt-print>
    </div>

    <div *ngIf="viewPaymentChallanPrint" onafterprint="afterPrint()" id="{{pageId}}viewPaymentChallanPrintId">
        <app-payment-followup-challan-print-rpt></app-payment-followup-challan-print-rpt>
    </div>

</body>

</html>