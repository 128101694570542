import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import { StockService } from "src/app/dataService/stock-service";
import * as qz from 'qz-tray';

@Component({
    selector: 'app-re-generate-barcode',
    templateUrl: './re-generate-barcode.component.html',
    styleUrls: ['./re-generate-barcode.component.css']
})
export class ReGenerateBarcodeComponent implements OnInit {
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    enteredLrNumber: string = '';
    enteredArticle: any;
    showSpinnerForAction = false;
    showArticleNo = false;
    selectedArtType: string = 'all';
    PrintBarcode = [];
    pageId = "rgbc";
    lrDto: LRDto = new LRDto();
    constructor(
        private router: Router,
        private stockService: StockService,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    ngOnInit(): void { }

    logInPage(): void {
        this.router.navigate(['/authentication/login']);
    }

    clearFields(): void {
        this.enteredLrNumber = '';
        this.enteredArticle = '';
        this.selectedArtType = 'all';
        this.showArticleNo = false;
        this.changeDetectorRef.detectChanges();
    }

    onArtTypeChange(artType: string): void {
        this.selectedArtType = this.selectedArtType === artType ? '' : artType;
        this.showArticleNo = this.selectedArtType === 'single';
    }

    reGenerateBarcode(): void {

        this.lrDto.lrNumber = this.enteredLrNumber;
        this.lrDto.totalArticles = this.enteredArticle;
        this.showSpinnerForAction = true;

        this.stockService.getBarcodeDetailsService(this.lrDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.printBarcodes(response);
                } else {
                    swal("Warning", "No Barcodes Generated for this Lr Number!Plese Verify the Lr Number", "warning");
                    this.showSpinnerForAction = false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server error while getting details!", "warning");
            }
        );
    }

    printBarcodesold(lrDtoBarcode) {

        document.getElementById('printSection')!.style.display = 'block';


        this.PrintBarcode = [];
        lrDtoBarcode.forEach((barcode, index) => {
            this.PrintBarcode.push({
                barcode: barcode.barcodeValue,
                Name: barcode.lrNumber,
                Destination: barcode.stationCode,
                ArticleIndex: `${barcode.articles}/${barcode.totalArticles}`
            });
        });

        setTimeout(() => {

            window.print();

        }, 1000);
        window.addEventListener("afterprint", (onclick) => {
            document.getElementById('printSection')!.style.display = 'none';
            this.clearFields();

        });

        this.changeDetectorRef.detectChanges();
    }
    printBarcodes(lrDtoBarcodeArray: any[]) {
        this.PrintBarcode = [];
        if (!lrDtoBarcodeArray || !lrDtoBarcodeArray.length) {
            swal("Error", "No barcodes available for printing", "info");
            return;
        }
    
        // Prepare barcode list
        this.PrintBarcode = lrDtoBarcodeArray.map((lrDtoBarcode, index) => ({
            barcode: lrDtoBarcode.barcodeValue,
            Name: lrDtoBarcode.lrNumber,
            Destination: lrDtoBarcode.stationCode,
            ArticleIndex: `${index + 1}/${lrDtoBarcodeArray.length}`,
        }));
    
        setTimeout(() => {
            const printSection = document.getElementById('printSection');
            if (!printSection) {
                swal("Error", "Print section not found", "info");
                return;
            }
    
            const barcodePages = printSection.querySelectorAll('.barcode-page'); // Get all barcode divs
            if (barcodePages.length === 0) {
                swal("Error", "No barcodes available for printing", "info");
                return;
            }
    
            const printerName = this.userDataDtoReturnSession.barcodePrinterName;
            if (!printerName) {
                swal("Error", "Printer name not configured", "error");
                return;
            }
    
            qz.websocket.connect()
                .then(() => {
                    const config = qz.configs.create(printerName);
    
                    // Create multiple print jobs for each barcode
                    const printJobs = Array.from(barcodePages).map((barcodePage) => ({
                        type: 'html',
                        format: 'plain',
                        data: `
                        <html>
                        <head>
                            <style>
                                .barcode-page {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    page-break-before: always;
                                    page-break-after: always;
                                    transform: rotate(-90deg) translate(-100%);
                                    transform-origin: top left;
                                    height: 100vh;
                                    width: 100vw;
                                }
                                .barcode-text, .barcode-print, .barcode-details, .barcode-details-art {
                                    margin: 0;
                                    padding: 0;
                                    text-align: center;
                                    font-weight: bold;
                                }
                                .barcode-text {
                                    font-size: 30px;
                                }
                                .barcode-print {
                                    text-align: center;
                                    width:85%;
                                }
                                .barcode-details, .barcode-details-art {
                                    font-size: 50px;
                                }
                            </style>
                        </head>
                        <body>
                            ${barcodePage.outerHTML}
                        </body>
                        </html>`
                    }));
    
                    return printJobs.reduce((prevPromise, printJob) => {
                        return prevPromise.then(() => qz.print(config, [printJob]));
                    }, Promise.resolve());
                })
                .then(() => {
                    swal("Success", "Barcodes printed successfully", "success");
                })
                .catch((err) => {
                    console.error("Error during printing:", err);
                    swal("Error", "Failed to print barcodes. Please check your printer connection.", "error");
                })
                .finally(() => {
                    qz.websocket.disconnect()
                        .catch(disconnectErr => console.warn("Error during disconnection:", disconnectErr));
                    this.clearFields();
                    
                });
    
        }, 500);
    }
    




}
